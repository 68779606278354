<template>
  <div class="OrderDetails">
    <div class="details_case">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_lb@2x.png" alt />
        <div class="case-title_max">订单基本信息</div>
        <div class="right">
          <!-- 回收方式 -->
          <!-- <div class="rightTrue" v-if="pageShowInfo.channel == 'Store'" :style="pageShowInfo.businessType == '01' ? 'background: rgba(255, 102, 51, 1)' : ''">{{pageShowInfo.businessTypes}}</div> -->
          <div
            class="rightTrue"
            v-if="pageShowInfo.isNiceBiz == true"
            style="background: rgba(255, 102, 51, 1)"
          >
            靓机订单
          </div>
          <div class="rightFalse" v-if="pageShowInfo.channel == 'Store'">
            {{ pageShowInfo.recycleWays }}
          </div>
          <div
            class="rightMid"
            v-if="
              pageShowInfo.state === '05' && pageShowInfo.channel == 'Store'
            "
          >
            差异订单
          </div>
          <div class="rightMid" v-if="pageShowInfo.channel == 'Merchant'">
            回收商自建订单
          </div>
          <div class="rightMid online" v-if="pageShowInfo.isEnablePay === true">
            线上订单
          </div>
        </div>
      </div>
    </div>
    <div class="details_section">
      <div>
        <span>订单编号：</span>
        <span>{{ this.pageShowInfo.orderNo }}</span>
      </div>
      <div>
        <span>订单状态：</span>
        <span>{{ pageShowInfo.states }}</span>
        <span
          style="color: #ff0000"
          v-if="
            pageShowInfo.state != '00' &&
            pageShowInfo.state != '03' &&
            pageShowInfo.barCodes != '--' &&
            pageShowInfo.businessType != '01'
          "
          >(已绑码)</span
        >
        <span
          style="color: #ff0000"
          v-if="
            pageShowInfo.state != '00' &&
            pageShowInfo.state != '03' &&
            pageShowInfo.barCodes == '--' &&
            pageShowInfo.businessType != '01'
          "
          >(未绑码)</span
        >
      </div>
      <div>
        <span>下单时间：{{ this.pageShowInfo.orderTime }}</span>
        <span></span>
      </div>
    </div>

    <template
      v-if="
        pageShowInfo.orderPayExtraView &&
        pageShowInfo.orderPayExtraView !== '--'
      "
    >
      <div class="details_case">
        <div class="case-title">
          <img
            class="case-title_icon"
            src="@/assets/images/icon_ygtjslb_2.png"
            alt
          />
          <div class="case-title_max">用户收款</div>
        </div>
      </div>
      <div class="details_section" style="height: 130px">
        <div style="display: flex">
          <div style="width: 33%">
            <span>收款人：</span>
            <span>{{ pageShowInfo.orderPayExtraView.name || "--" }}</span>
          </div>
          <div style="width: 33%">
            <span>联系电话：</span>
            <span>{{ pageShowInfo.orderPayExtraView.phone || "--" }}</span>
          </div>
          <div style="width: 33%">
            <span>身份证号：</span>
            <span>{{ pageShowInfo.orderPayExtraView.userIdCard || "--" }}</span>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 33%">
            <span>金额：</span>
            <span>{{ pageShowInfo.orderPayExtraView.amount || "--" }}</span>
          </div>
          <div style="width: 33%">
            <span>时间：</span>
            <span>{{ pageShowInfo.orderPayExtraView.createTime || "--" }}</span>
          </div>
          <div style="width: 33%">
            <span>审核人：</span>
            <span>{{ pageShowInfo.orderPayExtraView.auditName || "--" }}</span>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 33%">
            <span>付款状态：</span>
            <span>{{
              pageShowInfo.orderPayExtraView.transferStatus == "2"
                ? "放款中"
                : pageShowInfo.orderPayExtraView.transferStatus == "4"
                ? "放款成功"
                : pageShowInfo.orderPayExtraView.transferStatus == "5"
                ? "放款失败"
                : pageShowInfo.orderPayExtraView.transferStatus == "6"
                ? "已失效"
                : "--"
            }}</span>
          </div>
          <div style="width: 33%">
            <span>收款人签名：</span>
            <span
              class="see-btn"
              v-if="
                pageShowInfo.orderPayExtraView.userSignImg &&
                pageShowInfo.orderPayExtraView.userSignImg != '--'
              "
              @click="
                DialoghandleBtn(
                  pageShowInfo.orderPayExtraView.userSignImg,
                  'userSignImg'
                )
              "
              >点击查看</span
            >
            <span v-else>--</span>
          </div>
        </div>
      </div>
    </template>

    <template
      v-if="
        pageShowInfo.orderRebateExtraView &&
        pageShowInfo.orderRebateExtraView !== '--'
      "
    >
      <div class="details_case">
        <div class="case-title">
          <img
            class="case-title_icon"
            src="@/assets/images/icon_ygtjslb_3.png"
            alt
          />
          <div class="case-title_max">门店领佣</div>
        </div>
      </div>
      <div class="details_section" style="height: 90px">
        <div style="display: flex">
          <div style="width: 33%">
            <span>领取人：</span>
            <span>{{ pageShowInfo.orderRebateExtraView.name || "--" }}</span>
          </div>
          <div style="width: 33%">
            <span>金额：</span>
            <span>{{ pageShowInfo.orderRebateExtraView.amount || "--" }}</span>
          </div>
          <div style="width: 33%">
            <span>比例：</span>
            <span
              v-if="pageShowInfo.orderRebateExtraView.staffRebateRate != '--'"
              >{{
                pageShowInfo.orderRebateExtraView.staffRebateRate + "%"
              }}</span
            >
            <span v-else>--</span>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 33%">
            <span>时间：</span>
            <span>{{
              pageShowInfo.orderRebateExtraView.createTime || "--"
            }}</span>
          </div>
          <div style="width: 33%">
            <span>审核人：</span>
            <span>{{
              pageShowInfo.orderRebateExtraView.auditName || "--"
            }}</span>
          </div>
        </div>
      </div>
    </template>

    <template
      v-if="
        pageShowInfo.orderRefundExtraView &&
        pageShowInfo.orderRefundExtraView !== '--'
      "
    >
      <div class="details_case">
        <div class="case-title">
          <img
            class="case-title_icon"
            src="@/assets/images/icon_jiage.png"
            alt
          />
          <div class="case-title_max">退款信息</div>
          <div
            :style="
              pageShowInfo.orderRefundExtraView.state == '01'
                ? 'margin-left: 10px;color: green;'
                : 'margin-left: 10px;color: red;'
            "
          >
            （{{
              pageShowInfo.orderRefundExtraView.state == "00"
                ? "待退款"
                : pageShowInfo.orderRefundExtraView.state == "01"
                ? "退款完成"
                : "退款失败"
            }}）
          </div>
        </div>
      </div>
      <div class="details_section" style="height: 90px">
        <div style="display: flex">
          <div style="width: 33%">
            <span>退款原因：</span>
            <span>{{ pageShowInfo.orderRefundExtraView.cause || "--" }}</span>
          </div>
          <div style="width: 33%">
            <span>退款金额：</span>
            <span>{{ pageShowInfo.orderRefundExtraView.amount || "--" }}</span>
          </div>
          <div style="width: 33%">
            <span>时间：</span>
            <span>{{
              pageShowInfo.orderRefundExtraView.createTime || "--"
            }}</span>
          </div>
        </div>
        <div style="display: flex">
          <div style="width: 33%">
            <span>审核人：</span>
            <span>{{
              pageShowInfo.orderRefundExtraView.auditName || "--"
            }}</span>
          </div>
          <div style="width: 33%">
            <span>退款备注：</span>
            <span>{{ pageShowInfo.orderRefundExtraView.note || "--" }}</span>
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="
        pageShowInfo.orderRaisePriceExtraView &&
        pageShowInfo.orderRaisePriceExtraView !== '--'
      "
    >
      <div class="details_case">
        <div class="case-title">
          <img
            class="case-title_icon"
            src="@/assets/images/icon_jiage.png"
            alt
          />
          <div class="case-title_max">门店加价</div>
        </div>
      </div>
      <div class="details_section" style="height: 50px">
        <div style="display: flex">
          <div style="width: 33%">
            <span>金额：</span>
            <span>{{
              pageShowInfo.orderRaisePriceExtraView.amount || "--"
            }}</span>
          </div>
          <div style="width: 33%">
            <span>比例：</span>
            <span v-if="pageShowInfo.orderRaisePriceExtraView.rate != '--'">{{
              pageShowInfo.orderRaisePriceExtraView.rate + "%"
            }}</span>
            <span v-else>--</span>
          </div>
          <div style="width: 33%">
            <span>审核人：</span>
            <span>{{
              pageShowInfo.orderRaisePriceExtraView.auditName || "--"
            }}</span>
          </div>
        </div>
      </div>
    </template>

    <div class="details_case">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_lb@2x.png" alt />
        <div class="case-title_max">回收信息</div>
        <div class="left">
          <div>
            <span>询价商家：</span>
            <span>{{ this.pageShowInfo.companyName }}</span>
          </div>
          <div>
            <span>询价门店：</span>
            <span>{{ this.pageShowInfo.storeName }}</span>
          </div>
          <div>
            <span>询价人员：</span>
            <span
              >{{ this.pageShowInfo.staffName }}-{{
                this.pageShowInfo.staffMobile
              }}</span
            >
            <span class="copy" v-if="pageShowInfo.staffTypes != '--'">
              {{ pageShowInfo.staffTypes }}
            </span>
          </div>
          <div>
            <span style="color: #ff3300 !important">用户成交金额：</span>
            <span style="color: #ff3300 !important">{{
              this.pageShowInfo.finalPrice
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 回收信息 -->
    <div class="table_list">
      <el-table :data="[pageShowInfo]" border style="width: 100%">
        <el-table-column
          prop="typeName"
          label="旧机分类"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="brandName"
          label="旧机品牌"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phoneName"
          label="旧机名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="storageMemory"
          label="旧机规格"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="remark"
          label="备注说明"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="coreFaults" label="功能情况" align="center">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.detectionType == '02' &&
                scope.row.detectionInfo != '--'
              "
              style="color: #0981ff"
            >
              旧机已通过系统检测
            </div>
            <div
              v-if="
                scope.row.unableDetectionCause &&
                scope.row.unableDetectionCause != '--'
              "
              style="color: #0981ff"
            >
              旧机无法使用系统检测
            </div>
            <span
              v-if="
                (scope.row.coreFaults &&
                  scope.row.coreFaults != '--' &&
                  scope.row.coreFaults.length) ||
                scope.row.detectionType == '02' ||
                scope.row.isOneline
              "
              class="see-btn"
              @click="DialoghandleBtn(scope.row, 'faults')"
              >点击查看</span
            >
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="barCodes" label="绑定条码" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.barCodes != '--' && scope.row.barCodes">
              <span
                style="display: block"
                v-for="(item, index) in scope.row.barCodes.split(',')"
                :key="index"
                >{{ item }}</span
              >
            </span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column prop="brandName" label="旧机照片" align="center">
          <template slot-scope="scope">
            <span
              class="see-btn"
              @click="DialoghandleBtn(scope.row, 'imgDialog')"
              >点击查看</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="imei"
          label="旧机串号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="expressNo"
          label="快递单号"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 回收商家 -->
    <div class="details_case">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_lb@2x.png" alt />
        <div class="case-title_max">回收商家</div>
        <div class="left">
          <div>
            <span>成交回收商家：</span>
            <span>{{ this.pageShowInfo.merchantName }}</span>
          </div>
          <div>
            <span>报价金额：</span>
            <span>{{ this.pageShowInfo.quotePrice }}</span>
            <span
              >(返利金额:￥{{ this.pageShowInfo.allAddPrice }}，返利比例:{{
                this.pageShowInfo.allAddPriceRate
              }}%，店长红包:￥{{
                this.pageShowInfo.angPowSmStaff
              }}，店员红包:￥{{ this.pageShowInfo.angPowStaff }}，管理提成:￥{{
                this.pageShowInfo.managerReward || 0
              }}，门店显示价:￥{{ this.pageShowInfo.userShowPrice }})</span
            >
          </div>
          <div>
            <span>收货确认金额：</span>
            <span>{{ this.pageShowInfo.newPrice }}</span>
          </div>
          <!--          <div v-if="this.pageShowInfo.state == '05'">-->
          <!--            <span @click="DialoghandleBtn(pageShowInfo, 'difference')" class="see-difference">查看差异</span>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div
      v-if="
        pageShowInfo.businessType != '01' && pageShowInfo.channel != 'Merchant'
      "
      class="details_table"
    >
      <el-table :data="storeList" border style="width: 100%">
        <el-table-column
          prop="recycleMerchantName"
          label="报价商家"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="pageShowInfo.isNiceBiz == true">
              <span class="isdeal" v-if="scope.row.state == '01'"
                >靓机回收</span
              >
              <span class="isdeal" v-if="scope.row.isOriginal == true"
                >成交</span
              >
            </div>
            <div v-else>
              <span class="isdeal" v-if="scope.row.state == '01'">成交</span>
            </div>
            <span>{{ scope.row.recycleMerchantName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="quotePrice" label="报价金额" align="center">
          <template slot-scope="{ row }">
            {{ row.isMockQuoteOpen ? row.mkQuotePrice : row.quotePrice }}
          </template>
        </el-table-column>
        <el-table-column prop="quoteTime" label="报价时间" align="center">
          <template slot-scope="{ row }">
            {{ row.isMockQuoteOpen ? row.mkTime : row.quoteTime }}
          </template>
        </el-table-column>
        <el-table-column prop="staffName" label="报价人" align="center">
          <template slot-scope="{ row }">
            {{ row.isMockQuoteOpen ? row.mkStaffName : row.staffName }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="staffMobile" label="账号" align="center"></el-table-column>
        <el-table-column prop="staffType" label="角色" align="center"></el-table-column> -->
        <el-table-column
          prop="staffType"
          label="职位"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="quoteNum" label="报价次数" align="center"></el-table-column> -->
      </el-table>
    </div>
    <div class="details_double">
      <div class="left">
        <div class="details_case">
          <div class="case-title">
            <img
              class="case-title_icon"
              src="@/assets/images/icon_lb@2x.png"
              alt
            />
            <div class="case-title_max">订单动态</div>
          </div>
        </div>
      </div>
      <div class="details_double_item" style="display: flex">
        <div class="details_section">
          <div class="details_tips">门店端商家</div>
          <div>
            <span>下单时间：</span>
            <span v-if="orderFlow.buildOrder"
              >{{ orderFlow.buildOrder.orderTime }}({{
                orderFlow.buildOrder.staffName
              }}-{{ orderFlow.buildOrder.staffMobile }}-{{
                orderFlow.buildOrder.staffType
              }})</span
            >
            <span v-else>--</span>
          </div>
          <div>
            <span>确认时间：</span>
            <span v-if="orderFlow.ackOrder"
              >{{ orderFlow.ackOrder.orderTime }}({{
                orderFlow.ackOrder.staffName
              }}-{{ orderFlow.ackOrder.staffMobile }}-{{
                orderFlow.ackOrder.staffType
              }})</span
            >
            <span v-else>--</span>
          </div>
          <div>
            <span>绑码时间：</span>
            <span v-if="orderFlow.bindBarOrder"
              >{{ orderFlow.bindBarOrder.orderTime }}({{
                orderFlow.bindBarOrder.staffName
              }}-{{ orderFlow.bindBarOrder.staffMobile }}-{{
                orderFlow.bindBarOrder.staffType
              }})</span
            >
            <span v-else>--</span>
          </div>
          <div>
            <span>取消时间：</span>
            <span v-if="orderFlow.cancelOrder"
              >{{ orderFlow.cancelOrder.orderTime }}({{
                orderFlow.cancelOrder.staffName
              }}-{{ orderFlow.cancelOrder.staffMobile }}-{{
                orderFlow.cancelOrder.staffType
              }})</span
            >
            <span v-else>--</span>
            <span
              v-if="orderFlow.cancelOrder"
              class="see-btn"
              @click="DialoghandleBtn(orderFlow.cancelOrder, 'cancel')"
              >取消原因</span
            >
          </div>
          <div>
            <span>作废时间：</span>
            <span v-if="orderFlow.abolishOrder"
              >{{ orderFlow.abolishOrder.orderTime }}({{
                orderFlow.abolishOrder.staffName
              }}-{{ orderFlow.abolishOrder.staffMobile }}-{{
                orderFlow.abolishOrder.staffType
              }})</span
            >
            <span v-else>--</span>
            <span
              v-if="orderFlow.abolishOrder"
              class="see-btn"
              @click="DialoghandleBtn(orderFlow.abolishOrder, 'tovoid')"
              >作废原因</span
            >
          </div>
          <div>
            <span>退款时间：</span>
            <span v-if="orderFlow.refundOrder"
              >{{ orderFlow.refundOrder.orderTime }}({{
                orderFlow.refundOrder.staffName
              }}-{{ orderFlow.refundOrder.staffMobile }}-{{
                orderFlow.refundOrder.staffType
              }})</span
            >
            <span v-else>--</span>
            <span
              v-if="orderFlow.refundOrder"
              class="see-btn"
              @click="DialoghandleBtn(orderFlow.refundOrder, 'refund')"
              >退款原因</span
            >
          </div>
        </div>
        <div class="details_section">
          <div class="details_tips">成交回收商</div>
          <div>
            <span>抢单时间：</span>
            <span v-if="orderFlow.exclusiveOrder"
              >{{ orderFlow.exclusiveOrder.orderTime }}({{
                orderFlow.exclusiveOrder.staffName
              }}-{{ orderFlow.exclusiveOrder.staffType }})</span
            >
            <span v-else>--</span>
          </div>
          <div>
            <span>报价时间：</span>
            <span v-if="orderFlow.quoteOrder"
              >{{ orderFlow.quoteOrder.orderTime }}({{
                orderFlow.quoteOrder.staffName
              }}-{{ orderFlow.quoteOrder.staffType }})</span
            >
            <span v-else>--</span>
          </div>
          <div>
            <span>收货时间：</span>
            <span v-if="orderFlow.takeDeliverOrder"
              >{{ orderFlow.takeDeliverOrder.orderTime }}({{
                orderFlow.takeDeliverOrder.staffName
              }}-{{ orderFlow.takeDeliverOrder.staffType }})</span
            >
            <span v-else>--</span>
          </div>
          <div v-if="pageShowInfo.state == '05'">
            <span>申请追差时间：</span>
            <span v-if="orderFlow.aprOrder"
              >{{ orderFlow.aprOrder.orderTime }}({{
                orderFlow.aprOrder.staffName
              }}-{{ orderFlow.aprOrder.staffType }})</span
            >
            <span v-else>--</span>
          </div>
          <div>
            <span>退回时间：</span>
            <span v-if="orderFlow.returnOrder"
              >{{ orderFlow.returnOrder.orderTime }}({{
                orderFlow.returnOrder.staffName
              }}-{{ orderFlow.returnOrder.staffType }})</span
            >
            <span v-else>--</span>
            <span
              v-if="orderFlow.returnOrder"
              class="see-btn"
              @click="DialoghandleBtn(orderFlow.returnOrder, 'returns')"
              >退回原因</span
            >
          </div>
        </div>
      </div>
    </div>
    <!--弹框-->
    <GlobalDialog
      :title="dialogTitle"
      :show="dialogVisibleOrder"
      :width="840"
      top="10vh"
      @resetPopupData="resetPopupData"
      @submitPopupData="submitPopupData"
      @onClose="resetPopupData"
    >
      <div class="fault-box function_box" v-if="dialogTitle === '旧机功能情况'">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo :datainfo="detailData" />
        </div>
        <div>
          <!-- 估价单验机报告 -->
          <div class="test-box">
            <div
              class="test-result"
              v-if="detailData.functionView.errorList.length"
            >
              <Title
                :title="
                  '异常项（共' +
                  detailData.functionView.errorList.length +
                  '项）'
                "
              />
              <!-- 旧机无系统检测 -->
              <div
                class="mb10"
                v-if="
                  detailData.unableDetectionCause &&
                  detailData.detectionType == '01'
                "
              >
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  detailData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div
                class="mb10"
                style="display: flex; justify-content: space-between"
                v-if="detailData.detectionType == '02'"
              >
                <span class="fs16"
                  >检测机型：{{ detailData.detectionModel || "--" }}</span
                >
                <span class="fs16"
                  >录入串号：{{ detailData.imei || "--" }}</span
                >
              </div>
              <TestTable
                :externalorderType="externalorderType"
                :table-data="detailData.functionView.errorList"
              ></TestTable>
            </div>
            <div
              class="test-result"
              style="margin-top: 20px"
              v-if="detailData.functionView.normalList.length"
            >
              <Title
                :title="
                  '正常项（共' +
                  detailData.functionView.normalList.length +
                  '项）'
                "
              />
              <TestTable
                :externalorderType="externalorderType"
                :table-data="detailData.functionView.normalList"
              ></TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0">
          <Title title="店员备注" />
          <Remark
            :remark="detailData.remark"
            :againPhotoRemark="detailData.againPhotoRemark"
          />
        </div>
      </div>
      <div
        class="img-box"
        v-if="
          this.dialogTitle === '旧机图片' || this.dialogTitle === '收款人签名'
        "
      >
        <div class="img-tips-box" v-if="this.dialogTitle === '旧机图片'">
          <div
            @click="chooseImages(0)"
            class="img-tips"
            :class="imgShow ? 'img-tips-choose' : 'img-tips-default'"
          >
            旧机照片
          </div>
          <div
            @click="chooseImages(1)"
            v-if="this.DialogImgList[1].length > 0"
            class="img-tips"
            :class="imgShow ? 'img-tips-default' : 'img-tips-choose'"
          >
            补拍照片
          </div>
        </div>
        <div class="block">
          <div
            class="imgs-num"
            v-if="
              DialogImgList[imgIndex].length > 0 &&
              this.dialogTitle === '旧机图片'
            "
          >
            {{ imgShowIndex + 1 }}/{{ DialogImgList[imgIndex].length }}
          </div>
          <viewer :images="DialogImgList[imgIndex]">
            <el-carousel trigger="click" height="80vh" @change="getindex">
              <el-carousel-item
                v-for="item in this.DialogImgList[this.imgIndex]"
                :key="item.imgNo"
              >
                <div class="sec_img">
                  <img :src="item.img" alt="" />
                </div>
              </el-carousel-item>
            </el-carousel>
          </viewer>
        </div>
      </div>
      <div class="difference" v-if="this.dialogTitle === '查看回收差异'">
        <div style="display: flex">
          <div class="mb-10" style="flex: 0.3">
            <span style="font-weight: 700"> 承 担 方:</span>
            <span class="block-ml-10">{{
              dialogDiff.undertaker == "00"
                ? "回收商承担"
                : dialogDiff.undertaker == "01"
                ? "门店商承担"
                : "双方承担"
            }}</span>
          </div>
          <div class="mb-10" style="flex: 0.5">
            <span style="font-weight: 700"> 处理状态:</span>
            <span class="block-ml-10">{{
              dialogDiff.depreciateState == "00"
                ? "待确认"
                : dialogDiff.depreciateState == "01"
                ? "已确认"
                : "已拒绝"
            }}</span>
          </div>
          <div style="flex: 0.3"></div>
        </div>
        <div style="display: flex">
          <div class="mb-10" style="flex: 0.3">
            <span style="font-weight: 700"> 初始报价:</span>
            <span class="block-ml-10">￥{{ dialogDiff.quotePrice }}</span>
          </div>
          <div class="mb-10" style="display: flex; flex: 0.5">
            <span style="font-weight: 700">降价金额: </span>
            <div
              class="money"
              v-if="
                dialogDiff.undertaker == '00' || dialogDiff.undertaker == '02'
              "
            >
              ￥{{ dialogDiff.depreciatePriceRecycle }}
              <div>回收商承担</div>
            </div>
            <div
              class="money1"
              v-if="
                dialogDiff.undertaker == '01' || dialogDiff.undertaker == '02'
              "
            >
              ￥{{ dialogDiff.depreciatePriceStore }}
              <div>门店商承担</div>
            </div>
          </div>
          <div class="mb-10" style="flex: 0.3">
            <span style="font-weight: 700"> 实际回收:</span>
            <span class="block-ml-10">￥{{ dialogDiff.newPrice }}</span>
          </div>
        </div>

        <div class="mb-10" style="display: flex">
          <span style="font-weight: 700"> 差异凭证:</span>
          <div v-if="dialogDiff.depreciateImgss.length > 0">
            <viewer :images="dialogDiff.depreciateImgss">
              <img
                class="diff-imgs"
                v-for="(item, index) in dialogDiff.depreciateImgss"
                :key="index"
                :src="item"
                alt=""
              />
            </viewer>
          </div>
          <div v-else class="block-ml-10">无</div>
        </div>
        <div class="mb-10">
          <span style="font-weight: 700"> 差异日志:</span>
          <!-- <span class="block-ml-10">{{dialogDiff.depreciateRemark}}</span> -->
          <div style="margin-top: 10px">
            <div class="dialogDiff_double_item" style="display: flex">
              <div class="details_section">
                <div class="details_tips">成交回收商</div>
                <div>
                  <span>发起时间：</span>
                  <span v-if="orderFlow.depreciateInitiatorOrder"
                    >{{ orderFlow.depreciateInitiatorOrder.orderTime }}({{
                      orderFlow.depreciateInitiatorOrder.staffName
                    }}-{{ orderFlow.depreciateInitiatorOrder.staffType }})</span
                  >
                  <span v-else>--</span>
                </div>
                <div>
                  <span>差异原因：</span>
                  <span v-if="dialogDiff.depreciateRemark">{{
                    dialogDiff.depreciateRemark
                  }}</span>
                  <span v-else>--</span>
                </div>
                <div v-if="orderFlow.depreciateCancelOrder">
                  <span>取消时间：</span>
                  <span
                    >{{ orderFlow.depreciateCancelOrder.orderTime }}({{
                      orderFlow.depreciateCancelOrder.staffName
                    }}-{{ orderFlow.depreciateCancelOrder.staffType }})</span
                  >
                </div>
              </div>
              <div
                class="details_section"
                v-if="
                  dialogDiff.depreciateState != '00' &&
                  dialogDiff.undertaker != '00'
                "
              >
                <div class="details_tips">门店端商家</div>
                <div>
                  <span
                    >{{
                      dialogDiff.depreciateState == "10" ? "拒绝" : "通过"
                    }}时间：</span
                  >
                  <span
                    >{{ orderFlow.depreciateAckOrder.orderTime }}({{
                      orderFlow.depreciateAckOrder.staffName
                    }}-{{ orderFlow.depreciateAckOrder.staffType }})</span
                  >
                </div>
                <div v-if="dialogDiff.depreciateState == '10'">
                  <span>拒绝原因：</span>
                  <span>{{ orderFlow.depreciateAckOrder.remark }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.dialogTitle === '查看取消原因'">
        <span>{{ dialogcancel.remark || "无" }}</span>
      </div>
      <div v-if="this.dialogTitle === '查看作废原因'">
        <span>{{ dialogtovoid.remark || "无" }}</span>
      </div>
      <div v-if="this.dialogTitle === '查看退回原因'">
        <span>{{ dialogreturns.remark || "无" }}</span>
      </div>
      <div v-if="this.dialogTitle === '查看退款原因'">
        <span>{{ dialogreturns.remark || "无" }}</span>
      </div>
    </GlobalDialog>
    <el-image-viewer
      :zIndex="99999"
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="imgList"
    />
  </div>
</template>
<script>
import _api from "@/utils/request";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import GlobalDialog from "@/components/global/components/GlobalDialog.vue";
import Remark from "@/views/RecallOrder/componets/Remark.vue";
import Title from "@/views/RecallOrder/componets/Title.vue";
export default {
  name: "OrderDetails",
  components: {
    Remark,
    Title,
    GlobalDialog,
    TestTable,
    TestDeviceInfo,
    ElImageViewer,
  },
  data() {
    return {
      externalorderType: "",
      // 功能情况接口
      detailData: {
        functionView: {
          normalList: [],
          errorList: [],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: [],
      },
      dialogTitle: "",
      dialogVisibleOrder: false,
      orderNo: "",
      tabledata: [],
      faultsList: [],
      DialogImgList: [[], []],
      imgList: [],
      showViewer: false,
      imgShowIndex: 0,
      imgShow: true,
      imgIndex: 0,
      dialogDiff: {},
      // 自增放入页面的数据(经过处理后)
      pageShowInfo: {},
      // 报价回收商列表
      storeList: [],
      orderFlow: {
        buildOrder: { orderTime: "--" },
        ackOrder: { orderTime: "--" },
        bindBarOrder: { orderTime: "--" },
        cancelOrder: { orderTime: "--" },
        abolishOrder: { orderTime: "--" },
        quoteOrder: { orderTime: "--" },
        takeDeliverOrder: { orderTime: "--" },
        returnOrder: { orderTime: "--" },
        exclusiveOrder: { orderTime: "--" },
      },
      systemTestList: [
        {
          value: "01",
          key: "mobileNetworks",
          lable: "1.移动数据",
        },
        {
          value: "",
          key: "wlan",
          lable: "2.WI-FI",
        },
        {
          value: "",
          key: "bluetooth",
          lable: "3.蓝牙",
          // }, {
          //   value: "",
          //   key: 'conversation',
          //   lable: '4.通话功能',
        },
        {
          value: "",
          key: "camera",
          lable: "4.摄像头",
        },
        {
          value: "",
          key: "fingerprintOrFace",
          lable: "5.指纹/面容识别",
        },
        {
          value: "",
          key: "screenTouch",
          lable: "6.触屏功能",
        },
        {
          value: "",
          key: "screenDisplay",
          lable: "7.屏幕显示",
        },
        {
          value: "",
          key: "conversation",
          lable: "8.拨打电话",
        },
        {
          value: "",
          key: "charge",
          lable: "9.充电功能",
        },
      ],
      isSystem: false,
      isUnableTest: false,
      detectionInfo: null,
      cameraCause: [],
    };
  },
  created() {
    this.$store.commit("tagsView/ROUTER_PATH", this.$route.path);
    this.ClearSeachParams();
    console.log(this.$route.query.type);
    if (this.$route.query.type == "edit") {
      _api.orderInfo({ orderNo: this.$route.query.id }).then((res) => {
        if (res.code === 1) {
          const data = res.data ?? { fineness: { fineness: [] } };
          if (res.data.outType === "lb" || res.data.outType === "lb2") {
            data.fineness.fineness = data.fineness.fineness.map((v) => {
              v.value = Array.isArray(v.value) ? v.value : [];
              v.value = v.value.map((v) => {
                return {
                  isNormal: v.isNormal,
                  valueText: v.isNormal ? "正常" : "异常",
                };
              });
              return v;
            });
          }
          this.$store.commit("tagsView/GET_ORDERDETAILS", res.data);
        } else {
          this.$message.error("res.msg");
        }
      });
      this.getStoreList();
      this.getOrderFlow();
    }
  },
  computed: {
    orderDetailsList() {
      return this.$store.state.tagsView.orderDetailsList;
    },
  },
  watch: {
    orderDetailsList(n) {
      const pageShowInfo = JSON.parse(JSON.stringify(n));
      this.pageShowInfo = this.handleData(pageShowInfo);
      console.log(this.pageShowInfo);
    },
  },
  methods: {
    ClearSeachParams() {
      this.$store.dispatch("tagsView/ClearSeachParams", "");
    },
    DialoghandleBtn(row, type) {
      console.log(row);
      this.dialogVisibleOrder = true;
      if (type === "faults") {
        this.dialogTitle = "旧机功能情况";
        this.externalorderType = row.outType;
        // 功能情况接口
        _api.getInspectionReport({ orderNo: row.orderNo }).then((res) => {
          this.detailData = res.data;
          if (res.data.againPhotoRemark) {
            this.detailData.againPhotoRemark =
              res.data.againPhotoRemark.split("//");
          } else {
            this.detailData.againPhotoRemark = [];
          }
        });
      } else if (type === "imgDialog") {
        this.dialogTitle = "旧机图片";
        this.DialogImgList = [
          [...(row.images == "--" || !row.images ? [] : row.images)],
          [...(row.reImages == "--" || !row.reImages ? [] : row.reImages)],
        ];
      } else if (type === "difference") {
        this.dialogTitle = "查看回收差异";
        this.dialogDiff = row;
      } else if (type === "cancel") {
        this.dialogTitle = "查看取消原因";
        this.dialogcancel = row;
      } else if (type === "tovoid") {
        this.dialogTitle = "查看作废原因";
        this.dialogtovoid = row;
      } else if (type === "returns") {
        this.dialogTitle = "查看退回原因";
        this.dialogreturns = row;
      } else if (type === "refund") {
        this.dialogTitle = "查看退款原因";
        this.dialogreturns = row;
      } else if (type == "userSignImg") {
        this.dialogTitle = "收款人签名";
        this.DialogImgList = [[{ img: row }], []];
      }
    },
    chooseImages(e) {
      if (e == 0) {
        this.imgIndex = 0;
        this.imgShow = true;
      }
      if (e == 1) {
        this.imgIndex = 1;
        this.imgShow = false;
      }
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisibleOrder = false;
    },
    //弹窗确定
    submitPopupData() {
      this.dialogVisibleOrder = false;
    },
    /** 获取报价商 */
    getStoreList() {
      _api.orderStoreList({ orderNo: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          for (const item of res.data) {
            this.handleData(item);
          }
          this.storeList = res.data;
        }
      });
    },
    // 处理详情第二层数据
    handleFlowData(param) {
      const arr = [
        "abolishOrder", // 作废
        "ackOrder", // 确认
        "bindBarOrder", // 绑码
        "buildOrder", // 下单
        "cancelOrder", // 取消
        "exclusiveOrder", // 抢单
        "quoteOrder", // 报价
        "returnOrder", // 退回
        "takeDeliverOrder", // 收货
      ];
      for (const item of arr) {
        if (!param[item] || param[item] == undefined || param[item] == "") {
          param[item] = false;
        } else {
          for (const key in param[item]) {
            if (
              !param[item][key] ||
              param[item][key] == undefined ||
              param[item][key] == ""
            ) {
              param[item][key] == "--";
            }
          }
        }
      }
      return param;
    },
    // 获取动态
    getOrderFlow() {
      _api.orderFlow({ orderNo: this.$route.query.id }).then((res) => {
        if (res.code == 1) {
          this.orderFlow = this.handleFlowData(res.data);
          console.log(this.orderFlow);
        }
      });
    },
    /*************** 数据处理(保留原数据,新数据只是原数据后添加了一个s) ********************/
    handleData(param) {
      for (const key in param) {
        if (!param[key] || param[key] == undefined || param[key] == "") {
          if (param[key] != 0) {
            param[key] = "--";
          }
        }
        if (Object.prototype.toString.call(param[key]) === "[object Object]") {
          for (const secondKey in param[key]) {
            if (
              !param[key][secondKey] ||
              param[key][secondKey] == undefined ||
              param[key][secondKey] == ""
            ) {
              param[key][secondKey] = "--";
            }
          }
        }
        param.funcDetail = param.funcDetail ?? {};
      }
      // 旧机规格
      param.phoneMemorys = !param.phoneMemory ? "--" : param.phoneMemory;
      param.phoneStorages = !param.phoneStorage ? "--" : param.phoneStorage;
      param.storageMemory = param.phoneMemorys + "-" + param.phoneStorages;
      // 报价方式
      param.businessTypes = !param.businessType
        ? "--"
        : param.businessType == "01"
        ? "系统报价"
        : "工程师报价";
      // 回收方式
      param.recycleWays = !param.recycleWay
        ? "--"
        : param.recycleWay == "01"
        ? "以旧换新"
        : "独立回收";
      // 订单状态
      param.states = !param.state
        ? "--"
        : param.state == "00"
        ? "待确认"
        : param.state == "01"
        ? "已确认"
        : param.state == "02"
        ? "待支付"
        : param.state == "03"
        ? "已绑码"
        : param.state == "04"
        ? "已收货"
        : param.state == "05"
        ? "降价收货"
        : param.state == "10"
        ? "已取消"
        : param.state == "20"
        ? "已作废"
        : "已退回";
      // 角色
      param.staffTypes =
        !param.staffType || param.staffType == "--"
          ? "--"
          : param.staffType == "01"
          ? "创始人"
          : param.staffType == "02"
          ? "总监"
          : param.staffType == "03"
          ? "店长"
          : "店员";
      // 差异图片
      param.depreciateImgss =
        !param.depreciateImgs || param.depreciateImgs == "--"
          ? []
          : param.depreciateImgs.split(",");
      console.log(param);
      return param;
    },
    /*************** 数据处理 ********************/
    // 摄像头照片
    seeCamera(obj) {
      console.log(obj);
      this.imgList = [];
      this.showViewer = true;
      if (obj.cameraTestImgRear && obj.cameraTestImgRear != "--") {
        this.imgList.push(obj.cameraTestImgRear);
      }
      if (obj.cameraTestImgFront && obj.cameraTestImgFront != "--") {
        this.imgList.push(obj.cameraTestImgFront);
      }
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.function_box {
  max-height: 700px;
  overflow: auto;
}
.fs16 {
  font-size: 14px;
  margin-bottom: 10px;
}

.mb10 {
  margin-bottom: 10px;
}
.OrderDetails {
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;

  .details_bottom {
    display: flex;
    flex-direction: column;

    div {
      width: 50%;
    }
  }

  .details_case {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .case-title {
      margin: 20px;
      display: flex;
      align-items: center;

      .case-title_icon {
        width: 20px;
        height: 20px;
      }

      .case-title_max {
        font-weight: bold;
        color: #333333;
        font-size: 18px;
        height: 17px;
        line-height: 17px;
        margin-left: 10px;
        font-family: FZLanTingHei-B-GBK;
      }

      .left {
        display: flex;
        position: relative;
        justify-content: space-between;

        div {
          margin-left: 30px;

          > div {
            width: 32px;
            height: 22px;
            background: #21d8c2;
            border-radius: 3px 11px 11px 3px;
            color: white;
            line-height: 22px;
            position: absolute;
            text-align: center;
            top: 0;
            left: -5px;
            margin-right: 10px;
          }

          > span {
            font-family: FZLanTingHei-DB-GBK;
            font-size: 14px;
            font-weight: 400;
            color: #0981ff;
          }

          > input {
            border: none;
            background: none;
            color: #0981ff;
            padding: 0;
            width: 140px !important;
            font-size: 14px;
          }

          > .copy {
            height: 16px;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            background: #0981ff;
            color: #fff;
            margin-left: 8px;
            border-radius: 4px;
            font-size: 12px;
            padding: 0 10px;
          }
        }
      }

      .right {
        display: flex;
        color: #fff;

        .rightTrue {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ff687b;
          margin-left: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightFalse {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: #ffaf29;
          margin-left: 10px;
          border-radius: 5px;
          text-align: center;
        }

        .rightMid {
          width: max-content;
          padding: 5px 10px;
          font-size: 14px;
          background-color: rgba(102, 204, 153, 1);
          margin-left: 10px;
          border-radius: 5px;
          text-align: center;

          &.online {
            background: #ff687a !important;
          }
        }
      }
    }
  }

  .details_section {
    background: #f5f6fa;
    border-radius: 10px;
    height: 126px;
    margin: 0 20px;

    & > div {
      padding: 20px 10px 15px 20px;
      height: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;

      span:last-child {
        color: rgb(77, 77, 77);
      }
    }
  }

  .details_table {
    margin: 0 20px;

    .remark {
      font-size: 12px;
      font-weight: 400;
      color: #ff8080;
      margin: 10px;
    }
  }

  .details_double {
    height: 320px;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 50%;
    }

    .details_double_item {
      display: flex;

      > .details_section {
        background: #fff;
        border-radius: 10px;
        height: 216px;
        margin: 0 20px;
        width: 50%;

        div {
          padding: 20px 10px 15px 20px;
          height: 16px;
          font-size: 16px;
          font-weight: 400;
          color: #999999;
          line-height: 20px;

          span:last-child {
            color: rgb(77, 77, 77);
          }
        }

        .details_tips {
          margin-left: 22px;
          padding: 5px 10px;
          background: #0981ff;
          color: #fff;
          height: 28px;
          box-sizing: border-box;
          width: 100px;
          border-radius: 10px;
        }
      }
    }
  }

  .fault-box {
    color: #333333;
  }

  .faultBtn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;

    .faultBut_item {
      height: 40px;
      background-color: #e4ecfd;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 10px;
      width: 240px;

      > span {
        color: #0099ff;
      }
    }

    .faultSystem_item {
      background-color: #e4ecfd;
      border-radius: 6px;
      padding: 10px;
      margin-bottom: 10px;
      width: 380px;

      > span {
        color: #0099ff;
      }
    }
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .sec_img {
    width: 20vw;
    height: 80vh;
    margin: auto;

    img {
      width: 20vw;
      height: 80vh;
      object-fit: contain;
    }
  }
}

.dialogDiff_double_item {
  display: flex;

  > .details_section {
    background: #fff;
    border-radius: 10px;
    height: 216px;
    margin: 0 20px 0 0;
    width: 50%;

    div {
      padding: 20px 10px 15px 20px;
      height: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;

      span:last-child {
        color: rgb(77, 77, 77);
      }
    }

    .details_tips {
      padding: 5px 10px;
      background: #0981ff;
      color: #fff;
      height: 28px;
      box-sizing: border-box;
      width: 100px;
      border-radius: 10px;
    }
  }
}

.see-btn {
  color: #0981ff !important;
  cursor: pointer;
  text-decoration: underline;
}

.see-difference {
  color: #ff3300 !important;
  text-decoration: underline;
  cursor: pointer;
}

.money,
.money1 {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.money > div {
  margin-left: 5px;
  background: rgba(255, 102, 0, 1);
  border-radius: 10px;
  padding: 0px 10px;
}

.money1 > div {
  color: #fff;
  margin-left: 5px;
  background: rgba(11, 130, 255, 1);
  border-radius: 10px;
  padding: 0px 10px;
}

.diff-imgs {
  width: 98px;
  height: 98px;
  margin-left: 10px;
}

.block-ml-10 {
  display: inline-block;
  margin-left: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    width: max-content;
    height: 30px;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }
}

.hidden-2 {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.isdeal {
  background: #ff687b;
  display: inline-block;
  width: max-content;
  padding: 0px 4px;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  margin-right: 5px;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.test-el-tag1 {
  background-color: #21d8c2 !important;
  border-color: #21d8c2 !important;
  color: white !important;
}

.test-el-tag2 {
  background-color: #ff687b !important;
  border-color: #ff687b !important;
  color: white !important;
}

.system_icon {
  font-size: 12px;
  width: max-content;
  padding: 0 6px;
  height: 16px;
  line-height: 16px;
  background: #21d8c2;
  border-radius: 11px 8px 8px 0px;
  color: #fff;
  margin-left: 10px;
}

.txt-base {
  color: #444cf0;
}
</style>
