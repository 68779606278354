<template>
  <div class="remak_box">
    <!-- 下单备注 -->
    <div class="remark">
      <div class="remark-title">下单备注：</div>
      <div class="remark-cont">{{ remark || "--" }}</div>
    </div>
    <!-- 补拍备注 -->
    <div class="remark">
      <div class="remark-title">补拍备注：</div>
      <div class="remark-cont" v-if="againPhotoRemark.length">
        <div
          v-for="(item, index) in againPhotoRemark"
          :key="index"
          style="display: flex; align-items: center; margin-top: 10px"
        >
          <div class="cen_new">
            <div style="min-width: 65px;display: flex;align-items: center;">
              第
              <div class="cen_cilcl">{{ index + 1 }}</div>
              次：
            </div>
            {{ item }}
          </div>
          <div class="nowNew" v-if="againPhotoRemark.length - 1 == index">
            最新
          </div>
        </div>
      </div>
      <div class="remark-cont" v-else>--</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    remark: {
      type: String,
      default: "",
    },
    againPhotoRemark: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
<style lang="scss" scoped>
.nowNew {
  margin-left: 5px;
  text-align: center;
  line-height: 14px;
  min-width: 32px;
  height: 16px;
  background: #fef4f4;
  border-radius: 14px 14px 14px 0px;
  border: 1px solid #f83232;
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 10px;
  color: #f83232;
}

.cen_cilcl {
  font-family: PingFang SC;
  font-weight: 500;
  font-size: 11px;
  color: #706f7e;
  margin: 0 3px;
  text-align: center;
  line-height: 12px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #706f7e;
}

.cen_new {
  display: flex;
  align-items: center;
}
.remak_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;

  .remark {
    min-width: 48%;
    width: 48%;
    max-width: 48%;

    .remark-title {
      font-size: 14px;
      font-family: FZLanTingHeiS-R-GB;
      font-weight: 400;
      color: #666666;
      margin-bottom: 10px;
    }

    .remark-cont {
      padding: 16px 10px;
      box-sizing: border-box;
      width: 100%;
      min-height: 130px;
      background: #f9fbfd;
      border: 1px solid #c1d1ff;
      border-radius: 4px;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>