<template>
  <div class="phoneTable">
    <div class="btnStore">
      <div class="time-select">
        <div class="btn" :class="dateType == '01' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('01')">
          今日
        </div>
        <div class="btn" :class="dateType == '03' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('03')">
          本周
        </div>
        <div class="btn" :class="dateType == '05' ? 'btnType2' : 'btnType-after2'" @click="dataObtain('05')">
          本月
        </div>
      </div>
      <div>
        <el-date-picker v-model="valueTime" type="daterange" range-separator="至" value-format="timestamp"
          start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"></el-date-picker>
      </div>
      <div style="margin-left: 10px">
        <el-button type="primary" :loading="serchlong" @click="seachStore" round>查询</el-button>
        <el-button :loading="Excelloading" size="small" type="success" icon="el-icon-download"
          @click="clickExcel">导出Excel</el-button>
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%" :cell-style="Stylecell" @cell-click="cellclick">
      <el-table-column prop="rownum" label="序号" width="70" align="center">
        <template slot-scope="{ row }">
          <div class="numBG_1" v-if="row.rownum === 1">1</div>
          <div class="numBG_2" v-else-if="row.rownum === 2">2</div>
          <div class="numBG_3" v-else-if="row.rownum === 3">3</div>
          <div v-else>{{ row.rownum }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="门店商户" align="center"></el-table-column>
      <el-table-column prop="storeCount" label="门店数量" align="center"></el-table-column>
      <el-table-column prop="inquiryOrQuoteCount" label="询价量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.inquiryOrQuoteCount || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealCount" label="成交量" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.dealCount || '--' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="dealRatio" label="成交率" align="center"></el-table-column>
      <!-- <el-table-column
        prop="aprNum"
        label="差异量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="aprRate"
        label="差异率"
        align="center"
      ></el-table-column> -->
      <el-table-column prop="dealPrice" label="回收总额" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>回收总额</div>
            <div>
              <el-tooltip class="item" effect="dark" content="回收总额指成交订单与回收商所有交易总额" placement="top">
                <img src="../../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.dealPrice || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="grossProfit" label="总毛利" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>总毛利</div>
            <div>
              <el-tooltip class="item" effect="dark" content="总毛利=公司净利润+员工奖励" placement="top">
                <img src="../../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.grossProfit || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="profitRatio" label="总毛利率" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>总毛利率</div>
            <div>
              <el-tooltip class="item" effect="dark" content="总毛利率=毛利额/回收总额*100%" placement="top">
                <img src="../../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.profitRatio || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rebate" label="公司净利润" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>公司净利润</div>
            <div>
              <el-tooltip class="item" effect="dark" content="公司净利润指公司获得的利润额，不含员工红包" placement="top">
                <img src="../../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.rebate || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="rebateRatio" label="公司净利润率" align="center">
        <template slot="header">
          <div class="img-el-heard">
            <div>公司净利润率</div>
            <div>
              <el-tooltip class="item" effect="dark" content="公司净利率=公司净利润/回收总额*100%" placement="top">
                <img src="../../../../assets/images/home/ico_ts_red_.png" width="14px" height="14px" alt="" />
              </el-tooltip>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span>{{ scope.row.rebateRatio || 0 }}</span>
        </template>
      </el-table-column>
      <el-table-column width="250px" label="查看商家数据" align="center">
        <template slot-scope="{ row }">
          <span style="text-decoration: underline; cursor: pointer; color: #0981ff"
            @click="jumpPage(row, 'home')">首页数据</span><span
            style="margin-left:20px;text-decoration: underline; cursor: pointer; color: #0981ff"
            @click="jumpPage(row, 'ratio')">环比数据</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="orderPagina">
      <el-pagination float="right" background :current-page="this.page.pageNum" :page-size="this.page.pageSize"
        layout="total, prev, pager, next,jumper" :total="this.page.total" class="el-pagination-c"
        @current-change="handleCurrentChange" />
    </div>
    <el-dialog title="下载成功" :visible.sync="isShowDownloadSuccess" width="500px">
      <div style="font-size: 14px;font-weight: bold;color: #333;margin-bottom: 20px;text-align: center;">下载任务提交成功</div>
      <div style="font-size: 14px;color: #333;margin-bottom: 10px;text-align: center;">请前往系统管理-下载中心查看并下载数据</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowDownloadSuccess = false">稍后查看</el-button>
        <el-button type="primary" @click="config">立即查看</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _api from "@/utils/request";
import moment from "moment";
import { encryptByDES } from '@/utils/3DES'
export default {
  name: "phoneTable",
  data() {
    return {
      SeachParams: {},
      tableData: [],
      page: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      },
      valueTime: "",
      startTime: "",
      endTime: "",
      dateType: "01",
      companyId: "",
      orderType: "02",
      DialogDataList: [],
      serchlong: false,
      Excelloading: false,
      isShowDownloadSuccess: false
    };
  },
  created() {
    this.handleCurrentChange();
  },
  methods: {
    clickExcel() {
      this.Excelloading = true
      _api.platformOrderRankListExcel(this.SeachParams).then(res => {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => { //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
              this.isShowDownloadSuccess = false
            }
            if (data.code == 1) {
              this.isShowDownloadSuccess = true
            }
          } catch (err) {
            const fileName = "门店商家交易排行列表.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        })
        reader.readAsText(blob, 'utf-8'); // 设置读取的数据以及返回的数据类型为utf-8
        this.Excelloading = false
      }).catch(err => {
        this.Excelloading = false
        console.log(err)
      });
    },
    // cell样式
    Stylecell({ row, column, rowIndex, columnIndex }) {
      console.log(row);
      // if (columnIndex == 8) {
      //   return "text-decoration:underline;color:#0981ff";
      // }
    },
    // cell方法
    cellclick(row, column, cell, event) {
      console.log(row, "-----------");
      // if (column.label == "查看商家数据") {
      //   console.log("跳转");
      //   let id = encryptByDES(JSON.stringify(row.id),'63e42c2444e94c1ebca21d30d2aa39a5')
      //   const { href } = this.$router.resolve({
      //     name: "门店首页",
      //     query: {
      //       id,
      //       name: row.name
      //     },
      //   });
      //   window.open(href, "_blank");
      // }
    },
    jumpPage(row, type) {
      if (type == 'home') {
        // let id = encryptByDES(JSON.stringify(row.id),'63e42c2444e94c1ebca21d30d2aa39a5')
        const { href } = this.$router.resolve({
          name: "门店首页",
          query: {
            id: row.id,
            name: row.name
          },
        });
        window.open(href, "_blank");
      }
      if (type == 'ratio') {
        let id = encryptByDES(JSON.stringify(row.id), '63e42c2444e94c1ebca21d30d2aa39a5')
        const { href } = this.$router.resolve({
          name: "环比数据",
          query: {
            id,
            name: row.name
          },
        });
        window.open(href, "_blank");
      }
    },
    // 表格切换页面
    handleCurrentChange(val) {
      console.log(val);
      if (val) {
        this.page.pageNum = val;
      } else {
        this.page.pageNum = 1;
      }
      const baseRequest = {
        type: "02",
        startTime: this.startTime,
        endTime: this.endTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        dateType: this.dateType,
      };
      this.SeachParams = baseRequest
      this.serchlong = true
      _api.HomeOrderList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.serchlong = false
          this.tableData = res.data.records;
          this.page.pageNum = res.data.current;
          this.page.total = res.data.total;
        }
      }).catch(err => {
        this.serchlong = false
      });
    },
    //时间选择点击事件
    dataObtain(way) {
      this.dateType = way;
      this.valueTime = "";
      this.startTime = "";
      this.endTime = "";
      this.handleCurrentChange();
    },
    seachStore() {
      if (this.valueTime !== null && this.valueTime) {
        (this.dateType = ""),
          (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        (this.dateType = "01"), (this.startTime = ""), (this.endTime = "");
      }
      this.handleCurrentChange();
    },
    format(time) {
      let times = moment(time).format("YYYY-MM-DD");
      return times;
    },
    //打开系统下载中心
    config() {
      this.isShowDownloadSuccess = false
      let lybl = this.$router.resolve(
        {
          path: '/system/download',
        })
      window.open(lybl.href, "_blank");
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.img-el-heard {
  display: flex;
  justify-content: center;

  img {
    margin: 0px 0 -3px 3px;
  }
}

.phoneTable {
  padding: 0 20px 20px;

  .orderPagina {
    padding: 30px 30px 0 0;
    display: flex;
    justify-content: flex-end;
  }

  .numBG_1 {
    background-image: url("../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }

  .numBG_2 {
    background-image: url("../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }

  .numBG_3 {
    background-image: url("../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .store-dialog {
    /deep/.el-dialog__body {
      padding: 21px 20px 20px 20px;
    }
  }

  .btnStore {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    margin: 22px 0;

    .time-select {
      display: flex;

      .btn {
        box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
        margin: 0px 10px 0px 0;
        text-align: center;
        cursor: pointer;
      }

      .btnType-after2 {
        // width: 33%;
        width: 120px;
        height: 40px;
        background: #ffffff;
        color: #0981ff;
        line-height: 40px;
        border-radius: 20px;
      }

      .btnType2 {
        width: 120px;
        height: 40px;
        background: #0981ff;
        color: white;
        line-height: 40px;
        border-radius: 20px;
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    justify-content: space-between;
  }
}
</style>
