import request from './request'
export const http = {
    //系统管理-下载中心
    systemDownloadList(params) {
        return request({
            method: 'POST',
            url: '/agent/pc/download/center/list',
            data: params
        })
    },
    //系统管理-下载中心-获取funcode
    systemDownloadFunCode(params) {
        return request({
            method: 'GET',
            url: '/agent/pc/download/center/find-func',
            data: params
        })
    },
}