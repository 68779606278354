<template>
  <div class="OrderTable" v-loading="loading">
    <div class="top_bar">
      <div class="case" style="text-align: right">
        <div class="case-title">
          <img class="case-title_icon" src="@/assets/images/icon_lb@2x.png" alt />
          <div class="case-title_max">
            订单列表<span style="color: #ff5757; margin-left: 5px">({{ this.pages.total }})</span>
          </div>
        </div>
      </div>
      <el-button :loading="Excelloading" size="small" type="success" icon="el-icon-download"
        @click="submitExcel">导出Excel</el-button>
    </div>
    <div v-if="this.pages.total > 0">
      <div v-for="(item, index) in this.orderTableList" :key="index" class="order_list">
        <div class="line_top"></div>
        <div class="table_title">
          <div class="left">
            <!-- <div style="margin-right: 30px">
              <div>{{ index + 1 }}</div>
            </div> -->
            <div class="left-top">
              <div>
                <span class="first-text">{{ index + 1 }}</span>
                <span class="solo2" v-if="item.expressChannel == '01'">繁星物流</span>
                <span class="solo2" style="background: #84c0ff" v-if="item.expressChannel == '02'">线下物流</span>
                <!-- <span class="order-nice" style="opacity: 0" v-else></span> -->
                <span style="font-weight: 600">订单编号：</span>
                <span :id="'orderNo' + index">{{ item.orderNo }}</span>
                <span class="copy" @click="copy('orderNo' + index)">复制</span>
              </div>

              <div>
                <span style="font-weight: 600">下单时间：</span>
                <span>{{ item.orderTime }}</span>
              </div>
              <div>
                <span style="font-weight: 600">成交时间：</span>
                <span>{{ item.ackTime }}</span>
              </div>
              <!-- <div>
                <span style="font-weight: 600">询价门店：</span>
                <span>{{
                  item.storeName == "--" ? item.companyName : item.storeName
                }}</span>
              </div> -->
              <div>
                <span style="font-weight: 600">询价商家：</span>
                <span>{{
                  item.companyName !== "--" ? item.companyName : item.companyName
                }}</span>
              </div>
            </div>
            <div class="identification">

              <span class="order-nice" v-if="item.isNiceBiz == true">靓机订单</span>
              <span v-if="item.state === '05' && item.channel == 'Store'" class="rightTrue">
                差异订单
              </span>
              <span class="rightFalse" v-if="item.recycleWay === '01'">
                以旧换新
              </span>
              <span class="rightFalse2" v-if="item.recycleWay === '02'">
                独立回收
              </span>
              <span v-if="item.customerOrderNo && item.customerOrderNo != '--'" class="online-payment">线上估价订单</span>
            </div>
          </div>

          <div class="right">
            <div v-if="item.isEnablePay == true">
              <span class="online-payment">线上支付</span>
            </div>
            <div v-if="item.state != '10' &&
              item.state != '20' &&
              item.state != '30' &&
              item.isEnablePay == true
            " style="display: flex; align-items: center">
              <span v-if="item.userIsCollect == true" style="color: #80c269;">已到账门店，已付款给用户</span>
              <span v-else style="color: #ff687b;">已到账门店，未付款给用户</span>
            </div>
            <div v-if="item.state == '30' && item.isEnablePay == true" style="display: flex; align-items: center">
              <span v-if="item.returnedChannel == 'Store'" style="color: #80c269; margin-right: 20px">门店退款</span>
              <span v-else style="color: #ff687b; margin-right: 20px">拒收退回</span>
              <span v-if="item.userIsCollect == true" style="color: #80c269;">顾客收款后退单</span>
              <span v-else style="color: #ff687b;">顾客未收款退单</span>
            </div>
          </div>
        </div>

        <div class="table_list">
          <el-table :data="[item]" border style="width: 100%" id="excelOrder"
            :header-cell-style="{ background: 'white' }">
            <!-- *********************************修改*************************************** -->
            <el-table-column prop="phoneName" label="回收商品" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.phoneName }}</div>
                <!--                <div v-if="row.imei && row.imei != '&#45;&#45;'">-->
                <!--                  串号：{{ row.imei }}-->
                <!--                </div>-->
                <el-button type="primary" size="mini" v-if="row.healthCode !== '--'">{{ row.healthCode }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="phoneName" label="旧机串号" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.imei || '--' }}</div>
                <p class="lv_fc_line" @click="getFunction(row.orderNo, row.outType)">功能情况</p>
              </template>
            </el-table-column>
            <el-table-column prop="typeName" label="回收分类" align="center"></el-table-column>
            <el-table-column prop="name" label="旧机图片" align="center">
              <template slot-scope="{ row }">
                <div class="images-box" v-if="row.images && row.images.length > 0">
                  <img :src="row.images[0].img" alt="" />
                  <span @click="openImgBox(row)" class="text-down">共{{ row.images.length }}张</span>
                </div>
                <div v-else>暂无图片</div>
              </template>
            </el-table-column>
            <el-table-column prop="storeName" label="询价门店" align="center"></el-table-column>
            <el-table-column prop="phoneName" label="规格" align="center">
              <template slot-scope="{ row }">
                <span>
                  {{ row.phoneName == "--" ? "" : row.phoneName }}
                  {{ row.phoneMemory == "--" ? " " : row.phoneMemory }}
                  {{ row.phoneStorage == "--" ? "" : row.phoneStorage }}
                </span>
                <span v-if="row.phoneName == '--' &&
                  row.phoneMemory == '--' &&
                  row.phoneStorage == '--'
                ">--</span>
              </template>
            </el-table-column>
            <el-table-column prop="staffName" label="询价人员" align="center">
              <template slot-scope="{ row }">
                <span style="display: block">{{ row.staffMobile }}</span>
                <span v-if="row.isStaffSub == true" style="cursor: pointer" class="text-down"
                  @click="seeSubInfo(row)">{{
                    row.staffName }}</span>
                <span v-else>{{ row.staffName }}</span>
                <div :style="row.staffType == '03' && row.isStaffSub == true
                  ? 'cursor: pointer'
                  : ''
                  ">
                  <el-tag type="success" size="mini" effect="dark" @click="
                    row.staffType == '03' && row.isStaffSub == true
                      ? seeSubInfo(row)
                      : ''
                    ">{{
                      !row.staffType || row.staffType == null
                        ? "--"
                        : row.isMajor == true
                          ? "创建人"
                          : row.staffType == "01"
                            ? "子账号"
                            : row.staffType == "02"
                              ? "总监"
                              : row.staffType == "03" && row.isStaffSub == true
                    ? "店长-从账号"
                    : row.staffType == "03"
                    ? "店长"
                    : "店员"
                    }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="quoteMerchants" label="参与报价回收商(个)" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.quoteMerchants && row.quoteMerchants != '--'" style="cursor: pointer" class="text-down"
                  @click="openStoreList(row)">{{ row.quoteMerchants }}</span>
                <span v-else>{{ row.quoteMerchants }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="merchantName" label="成交回收商" align="center">
              <template slot-scope="{ row }">
                <div>{{ row.merchantName }}</div>
                <el-tag type="warning" size="small" v-if="row.isInside == true">内部回收</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="quotePrice" label="回收商成交金额(元)" align="center"></el-table-column>
            <el-table-column prop="finalPrice" label="用户成交金额(元)" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.finalPrice || "--" }}</span>
                <div class="color-red" @click="plusPrice(row)" v-if="row.useAddPrice && row.useAddPrice != '--'"
                  style="text-decoration: underline; cursor: pointer">
                  已加价：{{ row.useAddPrice }}元
                </div>
                <div class="color-red" @click="isInsideInfo(row)" v-if="row.isInside == true"
                  style="text-decoration: underline; cursor: pointer">
                  内部审批成交
                </div>
                <div @click="$refs['quoteDetailsDia'].open(row.orderNo, row.state)"
                  style="text-decoration: underline; cursor:pointer;color: #0c82ff">
                  查看明细
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="niceAckPayment" label="付款方" align="center">
              <template slot-scope="{ row }">
                <span>{{
                  row.niceAckPayment == "01"
                    ? "成交商家付款"
                    : row.niceAckPayment == "02"
                      ? "靓机商家付款"
                      : row.niceAckPayment
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="isBindBarCode" label="是否绑码" align="center">
              <template slot-scope="{ row }">
                <span class="color-green" v-if="row.isBindBarCode == '01'">是</span>
                <span class="color-red" v-if="row.isBindBarCode == '02'">否</span>
              </template>
            </el-table-column>
            <el-table-column prop="isBindBarCode" label="是否为重评订单" align="center">
              <!-- <template slot-scope="{ row }">
                <div
                  :class="row.isReBuild == true ? 'color-green' : 'color-red'"
                >
                  {{ row.isReBuild == true ? "是" : "否" }}
                </div>
                <div v-if="row.isReBuild == true">
                  原订单：<span
                    style="text-decoration: underline; cursor: pointer; color: #0981ff"
                    @click="toDetails(row)"
                    >{{ row.originalOrderNo }}</span
                  >
                </div>
              </template> -->
              <template slot-scope="{ row }">
                <div v-if="row.isOneline != true || (row.isOneline == true && row.isReBuild == true)">
                  <div :class="row.isReBuild == true ? 'color-green' : 'color-red'">
                    {{ row.isReBuild == true ? "是" : "否" }}
                  </div>
                  <div v-if="row.isReBuild == true">
                    原订单：<span style="
                        text-decoration: underline;
                        cursor: pointer;
                        color: #0981ff;
                      " @click="toDetails(row)">{{ row.originalOrderNo }}</span>
                  </div>
                </div>
                <!-- v-else -->
                <div v-else>
                  <div :class="row.isChxRebuild == true ? 'color-green' : 'color-red'
                    ">
                    {{ row.isChxRebuild == true ? "是" : "否" }}
                  </div>
                  <div v-if="row.isChxRebuild == true">
                    <span @click="viewDetails(row)" style="
                        text-decoration: underline;
                        cursor: pointer;
                        color: #0981ff;
                      ">查看详情</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="remakeCount" label="补拍记录" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.remakeCount && row.remakeCount != '--'" style="cursor: pointer" class="text-down"
                  @click="openRemakeList(row)">{{ row.remakeCount }}</span>
                <span v-else>{{ row.remakeCount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="订单状态" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.state === '00'">待确认</span>
                <span v-else-if="row.state === '01'">已确认</span>
                <span v-else-if="row.state === '02'">待支付</span>
                <span v-else-if="row.state === '03'">已绑码</span>
                <span v-else-if="row.state === '04'">已收货</span>
                <span v-else-if="row.state === '05'">降价收货</span>
                <span v-else-if="row.state === '10'">已取消</span>
                <span v-else-if="row.state === '20'">已作废</span>
                <span v-else-if="row.state === '30'">已退回</span>
                <div @click="DialoghandleBtn(row)" v-if="row.state === '10' || row.state === '20'" class="text-down">
                  {{
                    row.state === "10"
                      ? "取消原因"
                      : row.state === "20"
                        ? "作废原因"
                        : ""
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="expressNo" label="运单信息" align="center">
              <template slot-scope="{ row }">
                <div v-if="row.expressNo">{{ row.expressNo }}</div>
                <div v-if="row.expressNo != '--' &&
                  row.expressChannel != '01' &&
                  row.isSign == true
                ">
                  已签收
                </div>
                <div v-if="row.expressNo && row.expressNo != '--'">
                  <el-tag size="small" :type="row.expressChannel == '01' ? '' : 'warning'" effect="dark">
                    {{ row.expressChannel == "01" ? "繁星物流" : "线下物流" }}
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <!--            <el-table-column prop="customerOrderNo" label="顾客估价订单" align="center">-->
            <!--              <template slot-scope="{ row }">-->
            <!--                <div v-if="row.customerOrderNo && row.customerOrderNo != '&#45;&#45;'" style="cursor: pointer" class="text-down"-->
            <!--                  @click="toEvaluationDetails(row)">{{ row.customerOrderNo }}</div>-->
            <!--                <span v-else>{{ row.customerOrderNo }}</span>-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="channelAssessNo" label="估价单号" align="center">
              <template slot-scope="{ row }">
                <!-- @click="openAction(`/RecallOrder/evaluate?id=${row.channelAssessNo}`)" class="lv_fc_line"-->
                <span v-if="row.channelAssessNo && row.channelAssessNo !== '--'">{{ row.channelAssessNo }}</span>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <!-- *********************************修改*************************************** -->
            <el-table-column fixed="right" label="操作" width="120" align="center">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.row)" type="primary" round size="small">订单详情</el-button>
                <el-button v-if="scope.row.canRefund &&
                  scope.row.canRefund != '--' &&
                  loginStaffType == '01'
                " style="margin-left: 0; margin-top: 10px" @click="revokeOrder(scope.row)" type="danger" round
                  size="small">撤销订单</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="orderPagina">
        <el-pagination background :current-page="pages.currentPage" :page-size="10"
          layout="total, prev, pager, next,jumper" :total="this.pages.total" class="el-pagination-c"
          @current-change="handleCurrentChange" />
      </div>
    </div>
    <div v-else style="
        width: 100%;
        text-align: center;
        padding: 80px 0;
        font-size: 24px;
        color: #666666;
        background: white;
        font-weight: bold;
      ">
      暂无订单~
    </div>
    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="查看旧机照片" :visible="imgShow" @close="imgClose"
      top="5%">
      <div class="img-box">
        <div class="img-tips-box">
          <div @click="chooseImages(0)" class="img-tips" :class="imgShowOnly ? 'img-tips-choose' : 'img-tips-default'">
            旧机照片
          </div>
          <div v-if="this.DialogImgList[1].length > 0" @click="chooseImages(1)" class="img-tips"
            :class="imgShowOnly ? 'img-tips-default' : 'img-tips-choose'">
            补拍照片
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList[imgIndex].length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList[imgIndex].length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList[imgIndex]">
              <el-carousel trigger="click" height="80vh" @change="getindex">
                <el-carousel-item v-for="item in this.DialogImgList[this.imgIndex]" :key="item.imgNo">
                  <div class="sec_img">
                    <img :src="item.img" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
          <!-- <LookImg :images="imgList"/> -->
        </div>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :width="`${1400}px`" title="查看参与报价回收商" :visible="storeShow"
      @close="storeClose" top="15%">
      <div class="img-box">
        <div class="table_list">
          <el-table :data="storeList" border style="width: 100%" id="excelOrder" :cell-style="rowStyle"
            @cell-click="Bargain">
            <el-table-column prop="index" type="index" label="序号"></el-table-column>
            <el-table-column prop="recycleMerchantName" label="报价商家" align="center" width="200">
              <template scope="{ row }">
                <!-- <span
                  class="position isdeal"
                  v-if="row.recycleMerchantName == merchantName"
                  >成交</span
                >
                <span>{{ row.recycleMerchantName }}</span> -->
                <el-tag v-if="row.recycleMerchantName == merchantName" size="mini" type="danger" effect="dark"
                  style="margin-right: 10px">
                  成交
                </el-tag>
                <span>{{ row.recycleMerchantName }}</span>
                <div>
                  <el-tag v-if="row.isFixHighQuote &&
                    row.recycleMerchantName != merchantName &&
                    row.isFixHighQuote != '--'
                  " size="mini" type="warning" effect="dark" style="margin-right: 10px">
                    首次取价
                  </el-tag>
                  <el-tag v-if="row.isFixExpPrice &&
                    row.recycleMerchantName != merchantName &&
                    row.isFixExpPrice != '--'
                  " size="mini" type="warning" effect="dark">
                    议价取价
                  </el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="quotePrice" width="80" label="报价金额" align="center">
              <template slot-scope="{ row }">
                {{ row.isMockQuoteOpen ? row.mkQuotePrice : row.quotePrice }}
              </template>
            </el-table-column>
            <el-table-column prop="exclusiveTime" width="160" label="抢单时间" align="center"></el-table-column>
            <el-table-column prop="quoteTime" width="160" label="最新报价时间" align="center">
              <template slot-scope="{ row }">
                {{ row.isMockQuoteOpen ? row.mkTime : row.quoteTime }}
              </template>
            </el-table-column>
            <el-table-column prop="staffName" label="报价人" align="center">
              <template slot-scope="{ row }">
                {{ row.isMockQuoteOpen ? row.mkStaffName : row.staffName }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="staffMobile" label="账号" align="center"></el-table-column> -->
            <el-table-column prop="staffType" label="角色" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.isMockQuoteOpen">{{ row.mkQuoteIsMajor === true ? '创建人' : row.mkQuotePositionType == '01'
                  ? '子账号' : row.mkQuotePositionType == '02' ? '报价师' : '--'}}</span>
                <span v-else>{{ row.staffType }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quoteNum" width="80" label="报价次数" align="center"></el-table-column>
            <el-table-column prop="state" width="100" label="报价状态" align="center">
              <template scope="{ row }">
                <span v-if="row.isMockQuoteOpen">{{ row.mkQuoteState == '00' ? "待报价" : "已报价" }}</span>
                <span v-else>{{
                  row.state == "00-0"
                    ? "待报价"
                    : row.state == "00-1"
                      ? "报价中"
                      : row.state == "00-2"
                        ? "已报价"
                        : row.state == "01"
                          ? "已确认"
                          : row.state == "10" && row.exclusiveCancelTime != "--"
                            ? `取消抢单${row.exclusiveCancelTime}`
                            : "交易取消"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quoteNote" width="100" label="报价备注" align="center">
              <template scope="{ row }">
                <el-tooltip v-if="row.quoteNote != '--'" class="item" effect="dark"
                  :content="row.quoteNote == '--' ? '' : row.quoteNote" placement="bottom">
                  <span class="quoteNotecss">{{ row.quoteNote }}</span>
                </el-tooltip>

                <span v-else>{{ row.quoteNote }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="commission" width="110" label="外部预扣手续费" align="center">
              <template scope="{ row }">
                <span v-if="row.commission != '--'">{{ row.commission }}元</span>
                <div v-else>--</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="导出数据显示" :visible="excelVisible" :close-on-click-modal="false" :width="`${500}px`"
      @close="excelVisible = false" top="15%">
      <div style="display: flex; margin: 10px 20px">
        <div style="
            width: 80px;
            line-height: 30px;
            height: 30px;
            color: #666666;
            font-size: 14px;
          ">
          选时间段
        </div>
        <el-date-picker size="small" v-model="valueTime" type="daterange" range-separator="至" value-format="timestamp"
          :default-time="['00:00:00', '23:59:59']" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div style="margin: 40px 0; float: right">
        <el-button @click="excelVisible = false" size="mini">取消</el-button>
        <el-button @click="submitExcel" type="primary" size="mini">确认导出</el-button>
      </div>
    </el-dialog>
    <GlobalDialog :title="dialogTitle" :show="dialogVisibleOrder" :width="600" top="10vh"
      @resetPopupData="resetPopupData" @submitPopupData="submitPopupData" @onClose="resetPopupData">
      <span>{{ dialogcancel.remark || "无" }}</span>
    </GlobalDialog>

    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="查看报价记录" :visible="viewrecords"
      @close="viewrecordsClose" top="15%" bottom="10%">
      <div class="table_list" style="padding-bottom: 20px">
        <el-table :data="getUpdatedQuoteLog" max-height="300" border style="width: 100%" id="excelOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="160" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
                row.isMajor
                  ? "创建人"
                  : row.positionType == "01"
                    ? "子账号"
                    : "报价师"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="color: #0981ff; font-size: 16px; margin: 20px 0" v-if="remakeQuoteLog && remakeQuoteLog.length">
          补拍后报价记录
        </div>
        <el-table v-if="remakeQuoteLog && remakeQuoteLog.length" :data="remakeQuoteLog" max-height="300" border
          style="width: 100%" id="remakeOrder">
          <el-table-column prop="rank" width="70" label="报价次序" align="center"></el-table-column>
          <el-table-column prop="remakeTime" width="100" label="补拍时间" align="center"></el-table-column>
          <el-table-column prop="quoteTime" width="100" label="报价时间" align="center"></el-table-column>
          <el-table-column label="报价人" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.exclusiveQuoterName }}-{{
                row.isMajor
                  ? "创建人"
                  : row.positionType == "01"
                    ? "子账号"
                    : "报价师"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="报价金额" align="center">
            <template slot-scope="{ row }">
              <span>￥{{ row.quotePrice }}</span>
              <div>
                <el-tag type="danger" size="small" effect="plain" v-if="row.isInside">内部加价</el-tag>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="isNiceDevice"
            width="100"
            label="是否标记靓机"
            align="center"
          >
            <template scope="{ row }">
              <span>{{ row.isNiceDevice == true ? "是" : "否" }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="报价备注" align="center">
            <template slot-scope="{ row }">
              <el-tooltip v-if="row.quoteNote" class="item" effect="dark" :content="row.quoteNote" placement="top">
                <div class="quoteNotecss">
                  {{ row.quoteNote }}
                </div>
              </el-tooltip>
              <span v-else>--</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 启用禁用提示框 -->
    <el-dialog class="acc_dialog" title="退单提示" :visible.sync="revokeShow" :close-on-click-modal="false" width="500px"
      @closed="revokeClose">
      <div style="font-size: 14px; color: rgb(255, 104, 123); margin: 0 0 10px 0">
        说明：仅创建人、子账号可进行退单，退单须保证员工奖励余额充足，退单后订单获得的门店返利、红包、机款将退回到回收商账户。
      </div>
      <div style="margin-bottom: 20px; font-size: 16px; text-align: center">
        是否确认撤销该订单？
      </div>
      <div class="command" style="margin-bottom: 20px">
        <div style="margin-right: 10px">撤销原因:</div>
        <el-input style="width: 368px" maxlength="50" type="textarea" :rows="3" placeholder="请填写撤销原因，最多50字"
          v-model="revokeParams.refundRemark">
        </el-input>
      </div>
      <div class="command">
        <div>登录密码：</div>
        <el-input type="password" v-model="revokeParams.password" placeholder="请输入登录密码"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="revokeClose">取消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="revokeSure">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog class="add_price_dialog" title="加价详情" :visible.sync="plusPriceIF" :close-on-click-modal="false"
      width="650px" @closed="plusPriceClose">
      <div v-if="pcaddPric && pcaddPric.addPrice">
        <div class="add-price-title">
          <span>返利加价</span>
          <p class="add-price-type" v-if="pcaddPric.isCmccAddPrice">加价券加价</p>
          <p class="add-price-type" v-else>{{pcaddPric.addPriceUseType==='01'?'申请比例加价':'申请范围加价'}}</p>
        </div>
        <div class="add-price-conent" v-if="pcaddPric.isCmccAddPrice">
          <div style="padding: 20px;background: #F7F7F7;border-radius: 10px;">
            <div class="markup-coupon-box">
              <div class="markup-coupon-box-left">
                <p>￥<span class="advance-amount">{{pcaddPric.advanceAmount}}</span></p>
                <p>满{{pcaddPric.fullAmount}}元可用</p>
              </div>
              <div class="markup-coupon-box-right">
                <p class="markup-coupon-title">{{pcaddPric.rightsName}}</p>
                <p>{{pcaddPric.invalidDate}}前可用</p>
                <div>加价券ID：{{pcaddPric.couponId}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-price-conent" v-else>
          <ul class="onlyul">
            <li class="firstli">
              <div>
                申请加价金额: ￥{{ pcaddPric.addPrice || "" }}
                <span class="firstli_span" v-if="pcaddPric.addPriceUseType == '02'">(可加价范围: 1-{{ pcaddPric.addMaxPrice
                  }})</span>
                <span class="firstli_span" v-if="pcaddPric.addPriceUseType == '01'">(挪用返利比例: {{ pcaddPric.addPriceRate
                  }}%)</span>
              </div>
              <div>
                申请人员: {{ pcaddPric.applyStaffName }}-{{
                  pcaddPric.applyStaffMobile
                }}-{{
                  pcaddPric.applyIsMajor
                    ? "创建人"
                    : pcaddPric.applyStaffType == "01"
                      ? "子账号"
                      : pcaddPric.applyStaffType == "02"
                        ? "总监"
                        : pcaddPric.applyStaffType == "03"
                          ? "店长"
                : pcaddPric.applyStaffType == "04"
                ? "店员"
                : ""
                }}
              </div>
            </li>
            <li class="secondli">
              <div>批准加价金额: ￥{{ pcaddPric.passAddPrice }}</div>
              <div v-if="pcaddPric.auditStaffName">
                审核授权人:{{ pcaddPric.auditStaffName }}-{{
                  pcaddPric.auditStaffMobile
                }}-{{
                  pcaddPric.auditIsMajor
                    ? "创建人"
                    : pcaddPric.auditStaffType == "01"
                      ? "子账号"
                      : pcaddPric.auditStaffType == "02"
                        ? "总监"
                        : pcaddPric.auditStaffType == "03"
                          ? "店长"
                : pcaddPric.auditStaffType == "04"
                ? "店员"
                : ""
                }}
              </div>
            </li>
            <li style="margin-bottom: 20px" v-if="pcaddPric.auditStaffName">
              审核授权时间: {{ pcaddPric.auditTime }}
            </li>
            <li style="margin-bottom: 9px; height: 1px" v-if="!pcaddPric.auditStaffName"></li>
          </ul>
        </div>
      </div>
      <div v-if="pcaddPric && pcaddPric.usdAllAngPow">
        <div class="add-price-title">红包加价</div>
        <div class="add-price-conent">
          <ul class="onlyul">
            <li class="firstli">
              使用红包加价：￥{{ pcaddPric.usdAllAngPow || 0 }} (店员红包：￥{{
                pcaddPric.usdAngPowStaff || 0
              }}，店长红包：￥{{ pcaddPric.usdAngPowSmStaff || 0 }})
            </li>
            <li class="secondli">
              剩余红包：￥{{ pcaddPric.lastAllAngPow }} (店员红包：￥{{
                pcaddPric.angPowStaff || 0
              }}，店长红包：￥{{ pcaddPric.angPowSmStaff || 0 }})
            </li>
            <li style="margin-bottom: 9px; height: 1px"></li>
          </ul>
        </div>
      </div>
      <div style="height: 40px"></div>
    </el-dialog>
    <el-dialog title="从账号信息" :visible.sync="subInfoShow" :close-on-click-modal="false" width="300px"
      @closed="subInfoClose">
      <div style="font-size: 14px; color: #333333; padding-bottom: 21px">
        <div class="flex" style="margin-bottom: 20px">
          <span style="margin-bottom: 10px">微信头像：</span><img style="width: 70px; height: 70px; border-radius: 50%"
            :src="subInfo.img" alt="" />
        </div>
        <div class="flex" style="margin-bottom: 20px">
          <span>微信昵称：</span><span style="color: #666666">{{ subInfo.nickName }}</span>
        </div>
        <div class="flex" style="margin-bottom: 20px">
          <span>从账号姓名：</span><span style="color: #666666">{{ subInfo.staffName }}</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog class="acc_dialog" title="内部加价详情" :visible.sync="insideInfoShow" :close-on-click-modal="false"
      width="500px">
      <ul class="onlyul">
        <li class="firstli">
          期望成交金额: ￥{{ insideInfo.applyRealPrice || "" }}
        </li>
        <li class="secondli">批准成交金额: ￥{{ insideInfo.passAddPrice }}</li>
        <li>
          申请人员: {{ insideInfo.companyApplyStaffName }}-{{
            insideInfo.companyApplyStaffMobile
          }}-{{
            insideInfo.companyApplyIsMajor
              ? "创建人"
              : insideInfo.companyApplyStaffType == "01"
                ? "子账号"
                : insideInfo.companyApplyStaffType == "02"
                  ? "总监"
                  : insideInfo.companyApplyStaffType == "03"
                    ? "店长"
                    : insideInfo.companyApplyStaffType == "04"
          ? "店员"
          : ""
          }}
        </li>

        <li v-if="insideInfo.merchantAuditStaffName">
          审核授权人：{{ insideInfo.merchantAuditStaffName }}-{{
            insideInfo.merchantAuditStaffMobile
          }}-{{
            insideInfo.merchantAuditIsMajor
              ? "创建人"
              : insideInfo.merchantAuditStaffType == "01"
          ? "子账号"
          : "报价师"
          }}
        </li>
        <li style="margin-bottom: 20px" v-if="insideInfo.auditTime">
          审核授权时间: {{ insideInfo.auditTime }}
        </li>
      </ul>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="补拍记录" :visible="remakeRecordsShow"
      @close="remakeRecordsShow = false" top="15%" bottom="10%">
      <div class="table_list autoshow" style="padding-bottom: 20px">
        <el-table v-loading="remakeloading" :data="remakeImgList" max-height="300" border style="width: 100%"
          id="remakeList">
          <el-table-column prop="index" type="index" label="序号" align="center"></el-table-column>
          <el-table-column width="100" label="补拍类型" align="center">
            <template slot-scope="{ row }">
              <span>{{
                row.remakeType == "01" ? "主动补拍" : "要求补拍"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="申请补拍时间" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.applyTime || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="申请补拍人" align="center">
            <template slot-scope="{ row }">
              <span v-if="row.applyStaffId">{{ row.applyStaffName }}-{{
                row.applyIsMajor
                  ? "创建人"
                  : row.applyStaffType == "01"
                    ? "子账号"
                    : "报价师"
              }}</span>
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="remakeTime" min-width="100" label="完成补拍时间" align="center"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="重复询价详情" :visible.sync="enquiryDetailsShow" :close-on-click-modal="false" width="500px"
      @closed="enquiryDetailsClose">
      <div class="expected_info">
        <div class="expected_info_title">原订单</div>
        <div class="mt-10">
          <span style="color: #706f7e">原订单：</span>
          <span @click="handleClick(chxInfo)" style="text-decoration: underline; cursor: pointer; color: #0981ff">{{
            chxInfo.orderNo || "--" }}</span>
        </div>
        <div class="mt-10">
          <span style="color: #706f7e">原订单采货侠取价商家：</span>
          <span style="color: #333333">{{
            chxInfo.oriMerchantName || "--"
          }}</span>
        </div>
        <div class="mt-10">
          <span style="color: #706f7e">真实报价：</span>
          <span style="color: #333333">￥{{ chxInfo.quotePrice || '--' }}（一根线成交价：￥{{
            chxInfo.channelPrice || '--' }}）</span>
        </div>
      </div>
      <div class="expected_info">
        <div class="expected_info_title">新订单</div>
        <div class="mt-10">
          <span style="color: #706f7e">新订单报价方式：</span>
          <span style="color: red">{{
            chxInfo.quoteType == "all"
              ? "全部回收商报价"
              : chxInfo.quoteType == "sys"
                ? "系统自动报价"
                : "指定回收商报价"
          }}</span>
        </div>
        <div class="mt-10" v-if="chxInfo.quoteType != 'all'">
          <span style="color: #706f7e">新订单采货侠取价商家：</span>
          <span style="color: #333333">{{
            chxInfo.newMerchantName || "--"
          }}</span>
        </div>
        <div class="mt-10" v-if="chxInfo.quoteType != 'all'">
          <span style="color: #706f7e">商家报价：</span>
          <span style="color: #333333">￥{{ chxInfo.newQuotePrice || '--' }}（一根线成交价：￥{{ chxInfo.newChxPrice || "--"
            }}）</span>
        </div>
      </div>
    </el-dialog>
    <!-- 查看成交明细  -->
    <QuoteDetailsDia ref="quoteDetailsDia" />
    <!-- 旧机功能情况 -->
    <el-dialog v-bind="imeiTestProps" :before-close="() => imeiTestProps.show = false"
      :visible.sync="imeiTestProps.show">
      <div v-loading="imeiTestProps.isLoadding" class="function_box">
        <!-- 旧机基本情况所有情况都有--新旧/外部 -->
        <div class="test-info">
          <Title title="旧机基本情况" />
          <!-- 没有机型名称且不是老年机或者功能机，取phoneName字段(新询价方案也需要展现旧机相关信息和规格) -->
          <TestDeviceInfo :datainfo="detailData" />
        </div>
        <div>
          <!-- 估价单验机报告 -->
          <div class="test-box">
            <div class="test-result" v-if="detailData.functionView.errorList.length">
              <Title :title="'异常项（共' + detailData.functionView.errorList.length + '项）'" />
              <!-- 旧机无系统检测 -->
              <div class="mb10" v-if="detailData.unableDetectionCause &&
                detailData.detectionType == '01'
              ">
                <span class="fs16">旧机无法系统检测原因：</span>
                <span class="lv_fc_red fs16">{{
                  detailData.unableDetectionCause || "--"
                }}</span>
              </div>
              <div class="mb10" style="display: flex;justify-content: space-between;"
                v-if="detailData.detectionType == '02'">
                <span class="fs16">检测机型：{{ detailData.detectionModel || "--" }}</span>
                <span class="fs16">录入串号：{{ detailData.imei || "--" }}</span>
              </div>
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.errorList">
              </TestTable>
            </div>
            <div class="test-result" style="margin-top:20px" v-if="detailData.functionView.normalList.length">
              <Title :title="'正常项（共' + detailData.functionView.normalList.length + '项）'" />
              <TestTable :externalorderType="externalorderType" :table-data="detailData.functionView.normalList">
              </TestTable>
            </div>
          </div>
        </div>
        <!-- 新增店员备注--所有情况下都有 -->
        <div style="margin: 15px 0 25px 0;">
          <Title title="店员备注" />
          <Remark :remark="detailData.remark" :againPhotoRemark="detailData.againPhotoRemark" />
        </div>
      </div>
      <div slot="footer">
        <el-button v-if="imeiTestProps.type === '01'" @click="imeiTestProps.show = false" plain type="primary">关闭
        </el-button>
        <div v-else>
          <el-button @click="imeiTestProps.show = false">取 消</el-button>
          <el-button type="primary" @click="imeiTestProps.show = false">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="下载成功" :visible.sync="isShowDownloadSuccess" width="500px">
      <div style="font-size: 14px;font-weight: bold;color: #333;margin-bottom: 20px;text-align: center;">下载任务提交成功</div>
      <div style="font-size: 14px;color: #333;margin-bottom: 10px;text-align: center;">请前往系统管理-下载中心查看并下载数据</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowDownloadSuccess = false">稍后查看</el-button>
        <el-button type="primary" @click="config">立即查看</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { getHealthCode, openAction, setValue } from "@/utils/common"
import moment from "moment";
import { imeiTestProps } from "@/views/RecallOrder/initState";
import TestDeviceInfo from "@/views/RecallOrder/componets/TestDeviceInfo.vue";
import TestTable from "@/views/RecallOrder/componets/TestTable.vue";
import Title from "@/views/RecallOrder/componets/Title.vue"
import Remark from "@/views/RecallOrder/componets/Remark.vue";
import QuoteDetailsDia from './componets/QuoteDetailsDia.vue';

export default {
  name: "OrderTable",
  components: { TestTable, TestDeviceInfo, Title, Remark, QuoteDetailsDia },
  // components:{
  //   'LookImg': () => import('@/components/lookImg')
  // },
  data() {
    return {
      externalorderType: "",
      // 功能情况接口
      detailData: {
        functionView: {
          normalList: [],
          errorList: [],
        },
        basicFunctionList: [],
        plugFunctionList: [],
        informationList: []
      },
      imeiTestProps,
      // 成交明细
      dealDetailProps: {
        show: false,
        data: {},
        // true代表已确认，fasle代表未确认
        type: false,
        title: "报价明细"
      },
      plusPriceIF: false,
      getUpdatedQuoteLog: [],
      remakeQuoteLog: [],
      viewrecords: false,
      remakeRecordsShow: false,
      remakeloading: false,
      remakeImgList: [],
      pcaddPric: null,
      // 导出弹窗
      valueTime: "",
      excelVisible: false,
      /*********** 新增 **********/
      imgShow: false,
      imgShowOnly: true,
      DialogImgList: [[], []],
      imgShowIndex: 0,
      imgIndex: 0,
      storeShow: false,
      enquiryDetailsShow: false,

      storeList: [],
      /*********** 新增 **********/
      pages: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      revokeShow: false,
      revokeParams: {
        password: "",
        refundRemark: "",
        orderNo: "",
      },
      btnLoading: false,
      itemArr: [],
      itemTable: [],
      orderTableList: [],
      loading: false,
      dialogVisibleOrder: false,
      dialogTitle: "",
      dialogcancel: {
        remark: "",
      },
      loginStaffType: "",
      subInfoShow: false,
      insideInfoShow: false,
      insideInfo: {},
      subInfo: {
        img: "",
        staffName: "",
        nickName: "",
      },
      chxInfo: {},
      Excelloading: false,
      isShowDownloadSuccess: false
    };
  },
  created() {
    let loginStaffType = JSON.parse(localStorage.getItem("user")) ?? {};
    this.loginStaffType = loginStaffType.staffType;
    let startTime = moment()
      .day(moment().day() - 14)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推一周的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    this.ClearSeachParams();
    const baseRequest = {
      endTime: moment(endTime).format("x"),
      startTime: moment(startTime).format("x"),
      companyIdList: this.$route.query.companyId
        ? [Number(this.$route.query.companyId)]
        : [],
    };
    this.$store.commit("tagsView/SEACH_PARAMS", baseRequest);
    // this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      console.log(this.$store.state.tagsView.SeachParams);
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newval) {
      console.log(newval);
      this.pages.currentPage = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    openAction,
    //功能情况设置显示值
    setFunctionType(v) {
      let { isSystem, unableDetectionCause, functionList, isOtherTitileShow } = this.imeiTestProps;
      // 其他功能 系统检测||无法检测&&功能项存在&&（不是采货侠或者是采货侠订单且外部渠道为fx）
      if ((isSystem || unableDetectionCause) && functionList.length && (!v.isOneline || (v.isOneline && v.outType === 'fx'))) {
        isOtherTitileShow = true;
      } else {
        isOtherTitileShow = false;
      }
      this.imeiTestProps.isOtherTitileShow = isOtherTitileShow;
      // 02不是采货侠或者是采货侠订单且外部渠道为fx
      if (!v.isOneline || (v.isOneline && v.outType === 'fx')) {
        return this.imeiTestProps.type = '02';
      }
    },
    // 上述02类型  外部渠道盛达lb或者lb2，屏蔽正常开机选项
    setFunctionData(v) {
      const fineness = v.fineness ?? { fineness: [] };
      v.detectionInfo = v.detectionInfo ?? {};
      let { isFinenessShow, functionList, systemTestList, photoArr, allSystemTestList } = this.imeiTestProps;
      const { cameraTestImgFront, cameraTestImgRear } = v.detectionInfo;
      systemTestList = JSON.parse(JSON.stringify(allSystemTestList));
      let finenessData = Array.isArray(fineness.fineness) ? fineness.fineness : [];
      functionList = Array.isArray(functionList) ? functionList : [];
      // 盛达外部去除是否开机
      if (v.outType === 'lb' || v.outType === 'lb2') {
        functionList = functionList.filter(v => v.faultName !== '是否正常开机');
      }
      // 成色渲染 是采货侠 && 并且存在成色数据
      if (v.isOneline && fineness && finenessData.length) {
        isFinenessShow = true;
        finenessData = Array.isArray(finenessData) ? finenessData : [];
        finenessData = finenessData.map(item => {
          if (v.outType === 'lb' || v.outType === 'lb2') {
            item.value = item.value ?? [];
            item.value = item.value.map(v => {
              return {
                valueText: v.isNormal ? '正常' : '异常',
                isNormal: v.isNormal
              }
            })
          }
          return {
            name: item.label,
            value: item.value
          }
        })
      } else {
        isFinenessShow = false;
      }
      // 设置功能机
      functionList = functionList.map(v => {
        return {
          name: v.faultName,
          state: v.faultAnswer,
          reason: v.faultCause
        }
      });
      // 检测类型动态更改值的key
      const systemKeys = ['mobileNetworks', 'wlan', 'bluetooth'];
      if (v.detectionInfo) {
        // 设置系统验机项
        systemTestList = systemTestList.map(item => {
          item.value = v.detectionInfo[item.key] ?? '--';
          //前三个，正常的就是系统验机，异常的话是人工验机
          if (systemKeys.indexOf(item.key) >= 0) {
            if (v.detectionInfo[item.key] === '01') {
              item.isSystem = true;
            } else {
              item.isSystem = false;
            }
          }
          // 存在值才进行替换
          if (v.detectionInfo[item.tipKey]) {
            item.tips = v.detectionInfo[item.tipKey].replaceAll("&", "       ")
          }
          return item
        });
      }


      // 设置前置旧机照片
      if (cameraTestImgFront) {
        photoArr.push(cameraTestImgFront)
      }
      // 设置后置
      if (cameraTestImgRear) {
        photoArr.push(cameraTestImgRear)
      }
      this.imeiTestProps = {
        ...this.imeiTestProps,
        functionList,
        isFinenessShow,
        finenessData,
        systemTestList,
        photoArr,
      };
    },
    // 功能情况接口
    getDetail(orderNo) {
      _api.getInspectionReport({ orderNo }).then(res => {
        this.detailData = res.data;
        if (res.data.againPhotoRemark) {
            this.detailData.againPhotoRemark =
              res.data.againPhotoRemark.split("//");
          } else {
            this.detailData.againPhotoRemark = [];
          }
        this.imeiTestProps.isLoadding = false
      })
    },
    // 功能情况
    getFunction(v, outType) {
      console.log(outType);
      this.externalorderType = outType
      // 显示弹窗
      this.imeiTestProps = {
        ...this.imeiTestProps,
        phoneName: '',
        // 检测项
        tableData: [],
        // 功能情况
        functionList: [],
        // 系统检测结果
        systemTestResult: [],
        // 是否无法检测
        unableDetectionCause: "",
        // 是否系统检测且存在检测结果
        isSystem: false,
        // 其他功能标题显示，原需求模糊，抄详情写
        isOtherTitileShow: false,
        // 其他功能表格 原需求模糊，抄详情写
        isOtherTableShow: false,
        // 是否显示成色
        isFinenessShow: false,
        // 成色数据
        finenessData: [],
        // 旧机照片
        photoArr: [],
        // 系统验机项
        systemTestList: [],
        orderMode: "",//下单模式
        funcDetail: {},
        show: true,
        isLoadding: true,
      }
      // 请求详情
      this.getDetail(v);
    },
    // 加价弹窗
    plusPrice(row) {
      console.log(row, "加价弹窗row");

      _api.orderaddPriceInfo({ orderNo: row.orderNo }).then((res) => {
        this.pcaddPric = res.data;
      });
      this.plusPriceIF = true;
    },
    // 内部加价弹窗
    isInsideInfo(row) {
      console.log(row, "内部审批弹窗row");
      _api.insideApplyInfo({ orderNo: row.orderNo }).then((res) => {
        this.insideInfo = res.data;
      });
      this.insideInfoShow = true;
    },

    rowStyle({ row, column, rowIndex, columnIndex }) {
      if (column.property == "quoteNum" && !(row.quoteNum == 0) && !(row.quoteNum == "--")) {
        return "text-decoration:underline;color:#21a4f1;cursor:pointer;";
      }
    },
    Bargain(row, column, cell, event) {
      if (
        !(row.quoteNum == 0) &&
        !(row.quoteNum == "--") &&
        column.property == "quoteNum"
      ) {
        console.log(row, "------------");
        // this.storeShow = false;
        this.viewrecords = true;
        _api
          .getUpdatedQuoteLog({
            merchantId: row.recycleMerchantId,
            orderNo: row.orderNo,
          })
          .then((res) => {
            console.log(res.data, "报价信息");
            this.getUpdatedQuoteLog = res.data.normal ? res.data.normal : res.data;
            this.remakeQuoteLog = res.data.remake ? res.data.remake : [];
          });
      }
    },

    // oplist 查看报价记录
    oplist(row) {
      console.log(row, "&&&&&&&&&&&&&&&&&&&&");
      _api
        .getUpdatedQuoteLog({
          merchantId: row.recycleMerchantId,
          orderNo: row.orderNo,
        })
        .then((res) => {
          console.log(res, "^^^^^^^^^^^^^^");
          this.getUpdatedQuoteLog = res.data.normal;
          this.remakeQuoteLog = res.data.remake;
          this.viewrecords = true;
        });
    },

    viewrecordsClose() {
      this.viewrecords = false;
    },
    ClearSeachParams() {
      this.$store.dispatch("tagsView/ClearSeachParams", "");
    },
    submitExcel() {
      if (this.SeachParams.startTime || this.SeachParams.ackStartTime) {
        const params = {
          businessType: this.SeachParams.businessType,
          isBindBarCode: this.SeachParams.isBindBarCode,
          niceAckPayment: this.SeachParams.niceAckPayment,
          imei: this.SeachParams.imei,
          recycleWay: this.SeachParams.recycleWay,
          phoneName: this.SeachParams.phoneName,
          state: this.SeachParams.state,
          storeId: this.SeachParams.storeId,
          companyIdList: this.SeachParams.companyIdList,
          merchantId: this.SeachParams.merchantId,
          orderNo: this.SeachParams.orderNo,
          orderUnique: this.SeachParams.orderUnique,
          expressNo: this.SeachParams.expressNo,
          orderNoOneline: this.SeachParams.orderNoOneline,
          isNiceBiz: this.SeachParams.isNiceBiz,
          isEnablePay: this.SeachParams.isEnablePay,
          userIsCollect: this.SeachParams.userIsCollect,
          isReBuild: this.SeachParams.isReBuild,
          isInside: this.SeachParams.isInside,
          isReimg: this.SeachParams.isReimg,
          pageNum: this.pages.currentPage,
          pageSize: this.pages.pageSize,
          endTime: this.SeachParams.endTime,
          startTime: this.SeachParams.startTime,
          ackStartTime: this.SeachParams.ackStartTime,
          ackEndTime: this.SeachParams.ackEndTime,
          otherDateType: this.SeachParams.otherDateType,
          otherStartTime: this.SeachParams.otherStartTime,
          otherEndTime: this.SeachParams.otherEndTime,
          typeId: this.SeachParams.typeId,
          staffName: this.SeachParams.staffName,
          isUsedAddPrice: this.SeachParams.isUsedAddPrice,
          inspectorId: this.SeachParams.inspectorId,
          healthCode: this.SeachParams.healthCode
        };
        this.Excelloading = true
        _api
          .orderExcel(params)
          .then((res) => {
            console.log(res);
            const content = res;
            const blob = new Blob([content]);
            let reader = new FileReader(); // 创建读取文件对象
            reader.addEventListener("loadend", () => {
              //
              try {
                let data = JSON.parse(reader.result);
                if (data.code === 0) {
                  this.$message.error(data.msg || "导出失败");
                  this.isShowDownloadSuccess = false
                }
                if (data.code == 1) {
                  this.isShowDownloadSuccess = true
                }
              } catch (err) {
                const fileName = "订单列表.xlsx";
                if ("download" in document.createElement("a")) {
                  // 非IE下载
                  const elink = document.createElement("a");
                  elink.download = fileName;
                  elink.style.display = "none";
                  elink.href = URL.createObjectURL(blob);
                  document.body.appendChild(elink);
                  elink.click();
                  URL.revokeObjectURL(elink.href); // 释放URL 对象
                  document.body.removeChild(elink);
                } else {
                  // IE10+下载
                  navigator.msSaveBlob(blob, fileName);
                }
              }
            });
            reader.readAsText(blob, "utf-8"); // 设置读取的数据以及返回的数据类型为utf-8
            this.Excelloading = false
          })
          .catch((err) => {
            this.Excelloading = false
            console.log(err);
          });
      } else {
        this.$message({
          message: "请先选择时间查询再导出",
          type: "error",
        });
      }
    },
    clickExcel() {
      this.excelVisible = true;
    },
    handleClick(row) {
      let routeData = this.$router.resolve({
        path: "/profitSplit/orderDetails",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    // 去重评订单详情
    toDetails(row) {
      let routeData = this.$router.resolve({
        path: "/profitSplit/orderDetails",
        query: { type: "edit", id: row.originalOrderNo },
      });
      window.open(routeData.href, "_blank");
    },
    // 去估价订单详情
    toEvaluationDetails(row) {
      let routeData = this.$router.resolve({
        path: "/OnlinreyService/OrderDetails",
        query: {
          orderNo: row.customerOrderNo,
        },
      });
      window.open(routeData.href, "_blank");
    },
    handleCurrentChange(val) {
      this.loading = true;
      if (val) {
        this.pages.currentPage = val;
      }
      console.log(this.SeachParams);
      const vo = {
        businessType: this.SeachParams.businessType,
        isBindBarCode: this.SeachParams.isBindBarCode,
        niceAckPayment: this.SeachParams.niceAckPayment,
        recycleWay: this.SeachParams.recycleWay,
        phoneName: this.SeachParams.phoneName,
        imei: this.SeachParams.imei,
        state: this.SeachParams.state,
        storeId: this.SeachParams.storeId,
        companyIdList: this.SeachParams.companyIdList,
        merchantId: this.SeachParams.merchantId,
        orderNo: this.SeachParams.orderNo,
        orderUnique: this.SeachParams.orderUnique,
        expressNo: this.SeachParams.expressNo,
        orderNoOneline: this.SeachParams.orderNoOneline,
        isNiceBiz: this.SeachParams.isNiceBiz,
        isEnablePay: this.SeachParams.isEnablePay,
        userIsCollect: this.SeachParams.userIsCollect,
        isReBuild: this.SeachParams.isReBuild,
        isInside: this.SeachParams.isInside,
        isReimg: this.SeachParams.isReimg,
        pageNum: this.pages.currentPage,
        pageSize: this.pages.pageSize,
        endTime: this.SeachParams.endTime,
        startTime: this.SeachParams.startTime,
        ackStartTime: this.SeachParams.ackStartTime,
        ackEndTime: this.SeachParams.ackEndTime,
        otherDateType: this.SeachParams.otherDateType,
        otherStartTime: this.SeachParams.otherStartTime,
        otherEndTime: this.SeachParams.otherEndTime,
        typeId: this.SeachParams.typeId,
        staffName: this.SeachParams.staffName,
        isUsedAddPrice: this.SeachParams.isUsedAddPrice,
        inspectorId: this.SeachParams.inspectorId,
        healthCode: this.SeachParams.healthCode,
      };
      _api.orderList(vo).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.$emit("getUnPayUserOrders", res.data.unPayUserOrders);
          this.pages.total = res.data.ipage.total;
          this.itemTable = res.data.ipage.records;
          this.orderTableList = [];
          for (let i = 0; i < this.itemTable.length; i++) {
            this.itemArr = this.itemTable[i];
            this.orderTableList.push(this.handleData({
              ...this.itemArr,
              healthCode: getHealthCode(this.itemArr.healthCode)
            })); // 数据重新组装重置为--
            if (i > 10) {
              return;
            }
          }
          this.loading = false;
          console.log(this.orderTableList, "****************");
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    /********* 5-18新增 ************/
    copy(e) {
      const spanText = document.getElementById(e).innerText;
      const oInput = document.createElement("input");
      oInput.value = spanText;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      document.body.removeChild(oInput);
      this.$message.success("复制成功");
    },
    openImgBox(row) {
      console.log(row);
      this.imgShow = true;
      // this.imgList = e
      this.DialogImgList = [
        [...(row.images == "--" || !row.images ? [] : row.images)],
        [...(row.reImages == "--" || !row.reImages ? [] : row.reImages)],
      ];
      console.log(this.DialogImgList);
    },
    chooseImages(e) {
      if (e == 0) {
        this.imgIndex = 0;
        this.imgShowOnly = true;
      }
      if (e == 1) {
        this.imgIndex = 1;
        this.imgShowOnly = false;
      }
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
      this.imgList = [];
    },
    // 报价商家弹窗
    openStoreList(e) {
      console.log(e);
      this.merchantName = e.merchantName;
      _api.orderStoreList({ orderNo: e.orderNo }).then((res) => {
        console.log(res, "orderStoreList");
        if (res.code == 1) {
          for (const item of res.data) {
            this.handleData(item);
          }
          this.storeList = res.data;
        }
      });
      this.storeShow = true;
    },
    storeClose() {
      this.storeShow = false;
      this.storeList = [];
    },
    // 数字转换
    convertYypeNumer(num) {
      let v = num;
      // 是否类型正确
      let isType = true;
      if (typeof v !== 'number') {
        isType = false;
        v = Number(v);
        if (isNaN(v)) {
          v = 0;
        }
      }
      return {
        isType,
        v
      };
    },

    // 计算金额值
    computedMoney(num1, num2, isAdd = true) {
      const v1 = this.convertYypeNumer(num1);
      let v2 = this.convertYypeNumer(num2);
      if (!v1.isType && !v2.isType) {
        return '--'
      }
      v1.v = Math.abs(v1.v);
      v2.v = Math.abs(v2.v);

      return isAdd ? v1.v + v2.v : v1.v - v2.v;
    },
    //获取明秀
    getMoneyDetail(orderNo, cb) {
      _api.getQuoteCompute(orderNo).then(r => {
        let data = setValue(r.data);
        data = {
          ...data,
          // 回收商实收价
          netQuotePrice: this.computedMoney(data.quotePrice, data.depreciatePrice, false),
          // 员工红包
          angPow: this.computedMoney(data.angPowSmStaff, data.angPowStaff),
          // 公司压价返利
          companyRebate: this.computedMoney(data.orderCutPrice, data.allStaffCutPrice, false)
        }
        cb(data);
      })
    },
    // 查看明细
    moneyDetail(v) {
      this.getMoneyDetail(v.orderNo, data => {
        this.dealDetailProps = {
          ...this.dealDetailProps,
          show: true,
          // 待确认和已取消代表未确认，返回false，其他作为true
          type: ['00', '10'].indexOf(v.state) < 0,
          data
        };
      })
    },
    // 所有不为真的第一层数据重置为--
    handleData(param) {
      for (const key in param) {
        if (Object.prototype.toString.call(param[key]) === "[object Object]") {
          this.handleData(param[key]);
        } else if (param[key] === null || param[key] === undefined || param[key] === "") {
          param[key] = "--";
        }
      }
      return param;
    },
    DialoghandleBtn(row) {
      if (row.state === "10") {
        this.dialogTitle = "查看取消原因";
        this.dialogcancel.remark = row.cancelCause;
      } else {
        this.dialogTitle = "查看作废原因";
        this.dialogcancel.remark = row.abolishCause;
      }
      this.dialogVisibleOrder = true;
    },
    // //弹窗取消
    resetPopupData() {
      this.dialogVisibleOrder = false;
    },
    //弹窗确定
    submitPopupData() {
      this.dialogVisibleOrder = false;
    },
    // 撤销订单
    revokeOrder(row) {
      this.revokeShow = true;
      this.revokeParams.orderNo = row.orderNo;
    },
    // 撤销订单弹框关闭
    revokeClose() {
      this.revokeShow = false;
      this.revokeParams = {
        password: "",
        refundRemark: "",
      };
    },
    // 加价弹窗关闭按钮
    plusPriceClose() {
      this.pcaddPric = null;
      this.plusPriceIF = false;
    },
    // 撤销订单弹框确定
    revokeSure() {
      if (!this.revokeParams.refundRemark) {
        this.$message.error("请输入撤销原因");
        return;
      }
      if (!this.revokeParams.password) {
        this.$message.error("请输入登录密码");
        return;
      }
      this.btnLoading = true;
      _api
        .refundOrder(this.revokeParams)
        .then((res) => {
          if (res.code === 1) {
            this.$message.success("撤销成功");
            this.handleCurrentChange();
            this.revokeShow = false;
          }
          console.log(res);
          this.btnLoading = false;
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    // 从账号弹框
    seeSubInfo(row) {
      this.subInfo = {
        staffName: row.createStaffSubName,
        nickName: row.createStaffSubNickName,
        img: row.createStaffSubImg,
      };
      this.subInfoShow = true;
    },
    subInfoClose() {
      this.subInfo = {
        staffName: "",
        nickName: "",
        img: "",
      };
    },
    // 查看补拍记录
    openRemakeList(row) {
      this.remakeRecordsShow = true;
      this.remakeloading = true;
      _api.listRemakeByOrderNo({ orderNo: row.orderNo }).then((res) => {
        console.log(res.data, "补拍备注");
        this.remakeImgList = res.data;
        this.remakeloading = false;
      });
    },
    enquiryDetailsClose() {
      this.chxInfo = {};
    },
    viewDetails(row) {
      console.log(213)
      this.enquiryDetailsShow = true;
      _api.orderinfoRepeat({ orderNo: row.orderNo }).then((res) => {
        this.chxInfo = res.data;
      });
    },
    //打开系统下载中心
    config() {
      this.isShowDownloadSuccess = false
      let lybl = this.$router.resolve(
        {
          path: '/system/download',
        })
      window.open(lybl.href, "_blank");
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.function_box {
  max-height: 700px;
  overflow: auto;
}

.OrderTable {
  .top_bar {
    background: white;
    display: flex;
    justify-content: space-between;

    .case {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-items: center;

      .case-title {
        display: flex;

        .case-title_icon {
          width: 20px;
          height: 20px;
        }

        .case-title_max {
          font-weight: bold;
          color: #333333;
          font-size: 18px;
          // width: 80px;
          height: 17px;
          line-height: 17px;
          margin-left: 5px;
          font-family: FZLanTingHei-B-GBK;
        }
      }
    }
  }

  /deep/ .el-table th {
    padding: 3px 0 !important;
  }

  .orderPagina {
    float: right;
    margin: 50px;
  }

  .order_list {
    .line_top {
      background-color: white;
      width: 100%;
      height: 10px;
    }

    .table_title {
      display: flex;
      // height: 40px;
      padding: 10px 0;
      align-items: center;
      justify-content: space-between;
      background: #e4ecfd;
      border-radius: 6px 6px 0px 0px;

      .left {
        flex: 1;
        display: flex;
        position: relative;
        flex-direction: column;
        padding-left: 50px;

        // flex-wrap: wrap;
        // justify-content: space-between;
        .left-top {
          display: flex;
        }

        .left-top div {
          margin-right: 50px;
          display: flex;
          align-items: center;

          .first-text {
            display: inline-block;
            width: 32px;
            height: 22px;
            background: #ffaf29;
            border-radius: 3px 11px 11px 3px;
            color: white;
            line-height: 22px;
            position: absolute;
            text-align: center;
            top: 0;
            left: -5px;
            margin-right: 50px;
            margin-top: -2px;
          }

          >span {
            font-size: 14px;
            font-family: FZLanTingHei-DB-GBK;
            font-weight: 400;
            color: #0981ff;
          }

          >input {
            border: none;
            background: none;
            color: #0981ff;
            padding: 0;
            width: 140px !important;
            font-size: 14px;
          }

          >.copy {
            min-width: 36px;
            height: 16px;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            background: #0981ff;
            color: #fff;
            margin-left: 8px;
            border-radius: 4px;
            font-size: 12px;
            cursor: pointer;
          }
        }
      }

      .identification {
        display: flex;
        // flex-direction: column;
        align-items: center;
        margin-top: 10px;

        // padding-left: 50px;
        >span {
          margin-right: 10px;
        }

        >span:first-child {
          margin-top: 0;
        }

        .rightTrue {
          min-width: max-content;
          padding: 2px 8px;
          font-size: 14px;
          background-color: #ff687b;
          // margin-right: 10px;
          border-radius: 5px;
          text-align: center;
          color: #fff;
        }

        .rightFalse {
          min-width: max-content;
          padding: 2px 8px;
          font-size: 14px;
          background-color: #21d8c2;
          // margin-right: 10px;
          border-radius: 5px;
          text-align: center;
          color: #fff;
        }

        .rightFalse2 {
          background-color: #ffaf29;
          min-width: max-content;
          padding: 2px 8px;
          font-size: 14px;
          // margin-right: 10px;
          border-radius: 5px;
          text-align: center;
          color: #fff;
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 20px;
        color: #fff;

        .rightMid {
          font-size: 14px;
          font-family: FZLanTingHeiS-R-GB;
          font-weight: 400;
          text-align: center;
          width: 70px;
          height: 22px;
          background: #21d8c2;
          border-radius: 11px 0px 0px 11px;
        }
      }
    }
  }

  .order-nice {
    min-width: max-content;
    display: inline-block;
    padding: 2px 8px;
    font-size: 14px;
    background-color: #81d3f8;
    margin: 0 auto;
    border-radius: 5px;
    color: #fff !important;
    text-align: center;
    // margin-right: 10px;
    // margin-left: 50px;
  }

  .solo2 {
    min-width: max-content;
    background: #7dc165;
    border-radius: 11px;
    font-size: 14px;
    font-family: FZLanTingHeiS-R-GB;
    font-weight: 400;
    color: #ffffff !important;
    text-align: center;
    padding: 3px 10px;
    margin-right: 10px;
  }

  .online-payment {
    min-width: max-content;
    display: inline-block;
    padding: 2px 8px;
    font-size: 14px;
    background-color: #f57474;
    // margin: 0 auto;
    border-radius: 5px;
    color: #fff !important;
    text-align: center;
    // margin-left: 50px;
  }
}

// 表格样式+
.position {
  width: max-content;
  display: block;
  padding: 0px 4px;
  font-size: 14px;
  background-color: rgba(102, 204, 153, 1);
  margin: 0 auto;
  border-radius: 5px;
  color: #fff;
  text-align: center;
}

.position2 {
  width: max-content;
  display: block;
  padding: 0px 4px;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
}

.images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-box>img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.images-box>span {
  color: #0981ff;
  cursor: pointer;
}

.color-green {
  color: rgba(102, 204, 153, 1);
}

.color-red {
  color: #ff687b;
}

.text-down {
  text-decoration: underline;
  color: #0981ff;
}

// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }

  // 列表
}

.isdeal {
  background: #ff687b;
  margin-right: 5px;
  display: inline-block;
}

.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}

.command {
  display: flex;
  align-items: center;
}

.command .el-input {
  width: 80%;
}

.onlyul {
  li {
    margin-top: 10px;
  }

  .firstli {
    margin: 0;
    color: #3a9aff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .firstli_span {
      color: #ccc;
    }
  }

  .secondli {
    color: #ff6600;
    // font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.overflow-hiding {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quoteNotecss {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp: 2; //显示的行
}

.flex {
  display: flex;
  align-items: center;
}

.add-price-title {
  font-weight: bold;
  color: #333333;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  border-left: 3px solid #0981ff;
  margin-bottom: 10px;
  color: #0981ff;
  display: flex;
  align-items: center;
}

.add-price-conent {
  padding: 0 40px;
  .markup-coupon-box{
    width: 400px;
    height: 80px;
    background-image: url(https://img.fanxinghuishou.cn/20241210/125341172.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    .markup-coupon-box-left{
      width: 85px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 12px;
      .advance-amount{
        font-size: 24px;
      }
    }
    .markup-coupon-box-right{
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      >p{
        margin-bottom: 5px;
      }
      .markup-coupon-title{
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
    }
  }
}

.deal_detail_title {
  width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 10px;

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    background: #0c82ff;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translateY(-50%);
  }
}

.deal_detail_title1 {
  // width: 100%;
  padding-left: 16px;
  font-size: 14px;
  font-weight: 900;
  color: #000;
  position: relative;
  margin-bottom: 10px;
}

.deal_detail_title2 {
  font-size: 14px;
  color: #FF0000;
}

.deal_detail_inner {
  margin-bottom: 20px;
  background: #F2F2F2;
  font-size: 12px;
  color: #111;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 6px;
}

.deal_detail_line {
  margin-bottom: 16px;
}

.deal_detail_line-title {
  padding-left: 10px;
  position: relative;
  margin-bottom: 6px;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

.deal_detail_line-tips {
  transform: translateX(-5px);
}

.deal_detail_line-tips-blue {
  color: #0981FE;
}

.deal_detail_line-tips-red {
  color: #FF0000;
}

.test-function {
  margin-top: 20px;
}

.artificial-test-info {
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646464;
  margin-bottom: 10px;
}

.system-result-title {
  color: #646464;
  font-size: 16px;
  margin-bottom: 10px;
}

.fs16 {
  font-size: 16px;
  margin-bottom: 10px;
}

.mb10 {
  margin-bottom: 10px;
}
.add-price-type{
  padding: 0 10px;
  height: 20px;
  color: #ff6600;
  font-size: 14px;
  border: 1px solid #ff6600;
  border-radius: 10px 10px 10px 0;
  margin-left: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
}
</style>
