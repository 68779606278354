<template>
  <div class="class-list">
    <GlobalInfoBar title="下载中心" content="查看和下载导出列表" />
    <div class="button_top">
      <div class="radiobtn">
        <div class="faultBtn">
          <div v-for="(i, index) in funCodeList" v-bind:class="clickShow === index ? 'left' : 'right'"
            @click="BtnClick(index, i.code)" :key="index">
            {{ i.name }}
          </div>
          <!-- <div v-bind:class="clickShow=== 'QuoteOrderList'? 'left' : 'right'" @click="BtnClick('QuoteOrderList')">
                报价单列表
            </div>
            <div v-bind:class="clickShow=== 'QfaList'? 'left' : 'right'" @click="BtnClick('QfaList')">
                非最高报价单列表
            </div> -->
        </div>
      </div>
    </div>
    <GlobalChunk>
      <div class="flex" style="margin-bottom: 20px;justify-content: space-between;">
        <div class="flex">
          <div class="flex">
            <span style="width: 98px;">选择时间：</span>
            <el-date-picker size="small" v-model="detailedSearch.time" type="daterange" range-separator="~"
              start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions" style="border-radius:20px">
            </el-date-picker>
          </div>
          <el-button type="primary" v-loading="dialogLoading" style="margin-left: 10px;border-radius:20px" size="small"
            @click="searchSetting">查询</el-button>
        </div>
      </div>
      <GlobalTable ref="topUpTable" v-if="refresh" v-loading="dialogLoading" :columns="DialogTableColumns"
        :data="DialogDataList" :currentPage="pageNum" :total="total" @handleCurrentChange="handleCurrentChangeDialog">
        <el-table-column label="任务ID" slot="taskCode" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.taskCode ? `${row.taskCode}` : "" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="下载任务名称" slot="taskName" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.taskName == row.taskName ? row.taskName : "" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="任务状态" slot="taskState" align="center" width="80px">
          <template slot-scope="{ row }">
            <span v-if="row.taskState == '00'">生成中</span>
            <span v-if="row.taskState == '01'">已完成</span>
            <span v-if="row.taskState == '10'" style="color: #ff7284">生成失败</span>
            <span v-if="row.taskState == '20'">已过期</span>
          </template>
        </el-table-column>
        <el-table-column label="操作人" slot="operator" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.operator ? row.operator : "" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="导出时间" slot="createTime" align="center">
          <template slot-scope="{ row }">
            <div>{{ row.createTime ? row.createTime : "" }}</div>
          </template>
        </el-table-column>
        <el-table-column label="生成文件时间" slot="makeFileTime" align="center">
          <template slot-scope="{ row }">
            <div :style="row.opt == '02' ? 'color: green;' : 'color: red;'">
              {{ row.makeFileTime ? row.makeFileTime : "" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="失败原因" slot="failMsg" align="center">
          <template slot-scope="{ row }">
            <el-tooltip v-if="row.failMsg != '' && row.failMsg !== null" class="item" effect="dark" :content="row.failMsg"
              placement="top">
              <div class="noteWrap">{{ row.failMsg }}</div>
            </el-tooltip>
            <span v-else>- -</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="opea" align="center" width="150px">
          <template slot-scope="{ row }">
            <el-button type="info" v-if="row.taskState !== '01'" size="small">下载数据</el-button>
            <el-button type="primary" v-if="row.taskState == '01'" size="small"
              @click="downloadFile(row.fileUrl)">下载数据</el-button>
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>
  </div>
</template>
<script>
import _api from "@/utils/request";
  import {http} from "../../../utils/request/systemDownload";
import moment from "moment";
export default {
  name: "class-list",
  data() {
    return {
      refresh: true,
      staffType: "购买人",
      detailedSearch: {
        time: [moment().day(moment().day() - 30).format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")],
        imei: "",
      },
      total: 0,
      pageNum: 1,
      pageSize: 10,
      dialogLoading: false,
      DialogTableColumns: [
        { label: "任务ID", prop: "taskCode" },
        { label: "下载任务名称", prop: "taskName" },
        { label: "任务状态", slotName: "taskState" },
        { label: "操作人", prop: "operator" },
        { label: "导出时间", prop: "createTime" },
        { label: "生成文件时间", prop: "makeFileTime" },
        { label: "失败原因", slotName: "failMsg" },
        { label: "操作", slotName: "opea" },
      ],
      DialogDataList: [],
      pickerMinDate: null,
      day31: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day31 ||
              time.getTime() < this.pickerMinDate - this.day31
            );
          }
          return false;
        },
      },
      clickShow: 0,
      funCodeList: [],
      funcCode: null,
    };
  },
  created() {

      this.getFuncode()
  },
  methods: {
    BtnClick(index, type) {
      this.clickShow = index
      this.funcCode = type
      this.dialogLoading = true;
      this.refresh = false
      this.pageNum = 1;
      this.getDownloadList()
    },
    searchSetting() {
      this.pageNum = 1;
      this.getDownloadList()
    },
    handleCurrentChangeDialog(val) {
      this.pageNum = val;
      this.dialogLoading = true;
      this.getDownloadList()
    },
    //   //获取下载中心列表
    getDownloadList() {

      let data = {
        createStartTime: this.detailedSearch.time ? moment(this.detailedSearch.time[0]).format("x") : "",
        createEndTime: this.detailedSearch.time ? moment(this.detailedSearch.time[1]).format("x") : "",
        funcCode: this.funcCode,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }

      http.systemDownloadList(data).then((res) => {
        console.log(res)
        if (res.code === 1) {
          this.DialogDataList = res.data.records;
          this.total = res.data.total;
          this.pageNum = res.data.current;
          this.dialogLoading = false
        }


      }).catch(err => {

      })
      this.refresh = true
    },
    //   //获取funcode
    getFuncode() {
      http.systemDownloadFunCode().then((res) => {
        if (res.code === 1) {
          this.funCodeList = res.data.funcCodes
          this.funcCode = res.data.funcCodes[0].code
          this.getDownloadList()
        }


      }).catch(err => {

      })
    },
    //下载文件
    downloadFile(fileUrl) {
      this.$message.success("开始下载");
      setTimeout(() => {
        var net = window.open(fileUrl, "_blank")
        net.addEventListener("beforeunload", (e) => {
          this.$message.success("下载成功");
        })
      }, 3000)
    }
  },
};
</script>
<style scoped lang="scss" ref="stylesheet/scss">
.flex {
  display: flex;
  align-items: center;
}

.noteWrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button_top {
  cursor: pointer;
  margin-bottom: 20px;
  ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.faultBtn {
  height: 42px;
  background: #F9FBFD;
  border: 1px solid #C1D1FF;
  box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
  border-radius: 21px;
  display: flex;

  .left {
    text-align: center;
    color: white;
    width: 120px;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    height: 40px;
    background: #0981FF;
    border-radius: 20px;
  }

  .right {
    text-align: center;
    color: #333;
    width: 120px;
    height: 14px;
    font-size: 14px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    line-height: 40px;
    height: 40px;
    border-radius: 20px;
  }
}

.noteWrap {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>