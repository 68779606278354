
import Layout from '@/layout/layout'

const systemRouter = {
    path: '/system',
    component: Layout,
    redirect: 'noRedirect',
    name: '系统管理',
    children: [
        {
            path: 'DownLoad',
            component: () => import('@/views/system/download/index'),
            name: '下载中心',
            meta: { title: '下载中心', noCache: true }
        },
    ]
}

export default systemRouter