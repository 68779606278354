<template>
  <div class="GlobalTable">
    <div class="flex flex-jb list-top">
      <div class="flex">
        <img style="width: 20px; height: 20px; margin-right: 6px;" src="@/assets/images/iocn_ddlb@2x.png" alt>
        <span>账单列表</span>
      </div>
      <el-button :loading="Excelloading" size="small" type="success" icon="el-icon-download"
        @click="clickExcel">导出Excel</el-button>
    </div>
    <div class="statistical-data">
      <el-row type="flex" class="row-item" justify="start">
        <el-col :span="7" style="min-width: 500px;margin-right:20px">
          <div class="column-item">
            <!--  -->
            <img src="@/assets/images/ljtb.png" alt="">
            <div class="conent-div">
              <div><span style="font-size:20px;font-weight: bold;">累计</span>成交订单：<span
                  style="font-size:22px;font-weight: bold;color:#FFAF29;">{{ statisticalData.dealCount }}</span><span
                  style="font-size:16px;color:#FFAF29;">单</span></div>
              <div>渠道扣款：<span style="font-size:22px;font-weight: bold;color:#FFAF29;">{{ statisticalData.qdDealAmount
                  }}/{{ statisticalData.fxDealAmount }}</span><span style="font-size:16px;color:#FFAF29;">元</span></div>
            </div>
            <div class="wen">
              <el-tooltip class="item" effect="dark" content="选择时间下，通过渠道撮合的商家累计成交的订单，含退款或作废订单。" placement="top">
                <img src="../../../assets/images/whsi.png" style="width:16px;height:16px;cursor:pointer" alt="">
              </el-tooltip>
            </div>
          </div>
        </el-col>
        <el-col :span="7" style="min-width: 500px">
          <div class="column-item">
            <!--  -->
            <img src="@/assets/images/tktb.png" alt="">
            <div class="conent-div">
              <div><span style="font-size:20px;font-weight: bold;">退款</span>作废订单：<span
                  style="font-size:22px;font-weight: bold;color:#FF687B;">{{ statisticalData.refundCount }}</span><span
                  style="font-size:16px;color:#FF687B;">单</span></div>
              <div>渠道退回：<span style="font-size:22px;font-weight: bold;color:#FF687B;">{{ statisticalData.qdRefundAmount
                  }}/{{ statisticalData.fxRefundAmount }}</span><span style="font-size:16px;color:#FF687B;">元</span>
              </div>
            </div>
            <div class="wen">
              <el-tooltip class="item" effect="dark" content="选择时间下，通过渠道撮合的商家累计退款或作废的订单。" placement="top">
                <img src="../../../assets/images/whe.png" style="width:16px;height:16px;cursor:pointer" alt="">
              </el-tooltip>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="账单状态" slot="opt" align="center">
        <template slot-scope="{ row }">
          <!-- 01：撮合入账，02：撮合出账 -->
          <div>{{ row.opt == '01' ? '入账' : '出账' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="报价金额" slot="quotePrice" align="center">
        <template slot-scope="{ row }">
          <div>￥{{ row.quotePrice }}</div>
        </template>
      </el-table-column>
      <el-table-column label="订单成交时间" slot="ackTime" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.ackTime || '--' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="渠道扣款（次）" slot="Tax" align="center">
        <template slot-scope="{ row }">
          <div :style="row.opt == '01' ? 'color: green;' : 'color: red;'">{{ row.opt == '01' ? '+' : '-' }}{{
            row.channelTax }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button v-if="row.state != '02'" size="mini" type="primary" @click="goDetails(row)">订单详情
          </el-button>
        </template>
      </el-table-column>
    </GlobalTable>
    <el-dialog title="下载成功" :visible.sync="isShowDownloadSuccess" width="500px">
      <div style="font-size: 14px;font-weight: bold;color: #333;margin-bottom: 20px;text-align: center;">下载任务提交成功</div>
      <div style="font-size: 14px;color: #333;margin-bottom: 10px;text-align: center;">请前往系统管理-下载中心查看并下载数据</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isShowDownloadSuccess = false">稍后查看</el-button>
        <el-button type="primary" @click="config">立即查看</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "Table",
  props: {
  },
  data() {
    return {
      Excelloading: false,
      loading: false,
      seachDataList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      statisticalData: {},
      pageNum: 1,
      tableColumns: [
        { label: "订单编号", prop: "orderNo" },
        { slotName: 'opt' },
        { slotName: 'quotePrice' },
        { slotName: 'ackTime' },
        { label: "交易时间", prop: "createTime" },
        { label: "渠道", prop: "matchChannelName" },
        { label: "回收商家", prop: "merchantName" },
        { label: "交易门店商户", prop: "companyName" },
        { slotName: 'Tax' },
        { slotName: 'operation' },
      ],
      isShowDownloadSuccess: false
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal)
      this.page.pageNum = 1;
      this.handleCurrentChange()
    },
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val
        this.page.pageNum = val;
      }
      const baseRequest = {
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        matchChannelId: this.SeachParams.matchChannelId,
        opt: this.SeachParams.opt || '00',
        orderNo: this.SeachParams.orderNo || '',
        endTime: this.SeachParams.endTime,
        startTime: this.SeachParams.startTime,
        ackStartTime: this.SeachParams.ackStartTime,
        ackEndTime: this.SeachParams.ackEndTime,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      };
      this.loading = true
      _api.matchChannelAccountList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.ipage.records;
          this.page.total = res.data.ipage.total;
          this.page.pageNum = res.data.ipage.current;
          this.loading = false
          this.statisticalData = {
            dealCount: res.data.dealCount,
            refundCount: res.data.refundCount,
            matchCount: res.data.matchCount,
            qdDealAmount: res.data.qdDealAmount,
            fxDealAmount: res.data.fxDealAmount,
            qdRefundAmount: res.data.qdRefundAmount,
            fxRefundAmount: res.data.fxRefundAmount,
            fxMatchAmount: res.data.fxMatchAmount,
            qdMatchAmount: res.data.qdMatchAmount,
          }
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding == "function") {
          this.SeachParams.disuseLoding();
        }
      });
    },
    // 订单详情
    goDetails(row) {
      let routeData = this.$router.resolve({
        path: "/profitSplit/orderDetails",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    clickExcel() {
      const params = {
        ...this.SeachParams
      }
      this.Excelloading = true
      console.log(this.SeachParams);
      _api.matchChannelAccountExcel(params).then(res => {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => { //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              this.$message.error(data.msg || "导出失败");
              this.isShowDownloadSuccess = false
            }
            if (data.code == 1) {
              this.isShowDownloadSuccess = true
            }
          } catch (err) {
            const fileName = "分润账单列表.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        })
        reader.readAsText(blob, 'utf-8'); // 设置读取的数据以及返回的数据类型为utf-8
        this.Excelloading = false
      }).catch(err => {
        console.log(err)
        this.Excelloading = false
      });
    },
    //打开系统下载中心
    config() {
      this.isShowDownloadSuccess = false
      let lybl = this.$router.resolve(
        {
          path: '/system/download',
        })
      window.open(lybl.href, "_blank");
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .list-top {
    margin-bottom: 20px;
    color: #333333;
  }

  .statistical-data {
    width: 100%;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;

    .row-item {
      flex-wrap: wrap;
      font-size: 16px;
      margin: 10px 0;

      .column-item {
        // width: 500px;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 1px 9px 1px rgba(10, 117, 230, 0.13);
        border-radius: 10px;
        padding-left: 40px;
        display: flex;
        align-items: center;
        padding-right: 20px;

        .conent-div {
          flex: 1;
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 20px;
          color: #666666;
          font-size: 16px;
        }

        .wen {
          height: 100%;
          padding-top: 20px;
        }

        >img {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    justify-content: space-between;
  }
}
</style>
